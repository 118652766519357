/* eslint-disable import/no-cycle */
import React from 'react';
import { BuilderAction, BuilderActionType } from './actions';
import {
    addElement,
    addPage,
    deleteElement,
    deletePreviewPage,
    deleteStyles,
    propagateChanges,
    setPageName,
    setPreviewPage,
    setView,
    updateElement,
    updatePage,
    updateStyles,
} from './handlers';
import View from '../../../Model/View';
import PageData from '../../../Model/PageData';
import { DeviceType } from '../../../Model/VendexDevice';
import VendExp from '../../../Model/VendExp';

export type BuilderReducerState = {
    experience?: VendExp;
    view: View;
    name: string;
    pages: PageData[];
    styles: string;
    scripts?: { [key: string]: string };
    device: DeviceType;
    keybaordOpen: boolean;
    mediaLibrary?: {
        open: 'MEDIA' | 'UPLOAD';
        onChange?: (url: string) => void;
        selected?: string;
        type?: 'IMAGE' | 'VIDEO' | 'FONT';
    };
    addPage: boolean;
    fontOpen: boolean;
    previewPage?: PageData;
};

export const initialState: BuilderReducerState = {
    view: View.Preview,
    name: '',
    pages: [],
    keybaordOpen: false,
    fontOpen: false,
    styles: '',
    addPage: false,
    device: DeviceType.BIG_VEND,
};

export function BuilderReducer(
    state: BuilderReducerState,
    action: BuilderAction,
): BuilderReducerState {
    switch (action.type) {
        case BuilderActionType.SET_EXPERIENCE:
            // eslint-disable-next-line no-case-declarations
            const prevPage = state.previewPage
                ? action.payload.pages.find(
                      (el) => el.id === (state.previewPage as PageData).id,
                  )
                : undefined;
            return {
                ...state,
                pages: action.payload.pages,
                scripts: action.payload.scripts,
                styles: action.payload.styles,
                previewPage: prevPage,
                experience: action.payload,
            };
        case BuilderActionType.SET_VIEW:
            return setView(state, action.payload);
        case BuilderActionType.SET_PAGE_NAME:
            return setPageName(state, action.payload);
        case BuilderActionType.SET_PREVIEW_PAGE:
            return setPreviewPage(state, action.payload);
        case BuilderActionType.UPDATE_PAGE:
            return updatePage(state);
        case BuilderActionType.SET_CSS:
            return { ...state, styles: action.payload };
        case BuilderActionType.SET_SCRIPTS:
            return { ...state, scripts: action.payload };
        case BuilderActionType.UPDATE_CSS:
            return updateStyles(state, action.payload);
        case BuilderActionType.DELETE_CSS:
            return deleteStyles(state, action.payload);
        case BuilderActionType.SET_DEVICE:
            return { ...state, device: action.payload };
        case BuilderActionType.ADD_PAGE:
            return addPage(state, action.payload);
        case BuilderActionType.DELETE_PREVIEW_PAGE:
            return deletePreviewPage(state);
        case BuilderActionType.ADD_ELEMENT:
            return addElement(state, action.payload);
        case BuilderActionType.UPDATE_ELEMENT:
            return updateElement(state, action.payload);
        case BuilderActionType.DELETE_ELEMENT:
            return deleteElement(state, action.payload);
        case BuilderActionType.SET_MEDIA_LIBRARY:
            return { ...state, mediaLibrary: action.payload };
        case BuilderActionType.TOGGLE_KEYBOARD:
            return { ...state, keybaordOpen: !state.keybaordOpen };
        case BuilderActionType.TOGGLE_FONT:
            return { ...state, fontOpen: !state.fontOpen };
        case BuilderActionType.TOOGLE_ADD_PAGE:
            return { ...state, addPage: !state.addPage };
        case BuilderActionType.APPLY_CHANGES:
            return propagateChanges(state);
        default:
            return state;
    }
}

export const BuilderContext = React.createContext<{
    state: BuilderReducerState;
    dispatch: React.Dispatch<BuilderAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
