import Build, { SDK } from '../Model/Build';
import downloadFile from '../utils/dowloadFile';
import instance from './instance';

function UploadNewBuild(file: File): Promise<boolean> {
    const formData = new FormData();
    formData.append('file', file);
    return instance.post(`/builder`, formData).then((response) => {
        return response.data;
    });
}

function GetAllBuilds(): Promise<Build[]> {
    return instance.get(`/builders`).then((response) => {
        return response.data;
    });
}

function GetLatestBuildVersion(): Promise<string> {
    return instance.get(`/builder/latest-version`).then((response) => {
        return response.data;
    });
}

function DownloadBuild(version: string): Promise<boolean> {
    return instance
        .get(`/builder/${version}/download`, {
            responseType: 'blob',
        })
        .then((response) => {
            return downloadFile(response.data, `${version}.zip`);
        });
}

function GetAllSDKs(): Promise<SDK[]> {
    return instance.get(`vend-sdks`).then((response) => {
        return response.data;
    });
}

function UploadNewSDK(file: File): Promise<boolean> {
    const formData = new FormData();
    formData.append('file', file);
    return instance.post(`/vend-sdk`, formData).then((response) => {
        return response.data;
    });
}

function DownloadSDK(version: string): Promise<boolean> {
    return instance
        .get(`/vend-sdk/${version}/download`, {
            responseType: 'blob',
        })
        .then((response) => {
            return downloadFile(response.data, `${version}.zip`);
        });
}

export {
    UploadNewBuild,
    GetAllBuilds,
    GetLatestBuildVersion,
    DownloadBuild,
    GetAllSDKs,
    DownloadSDK,
    UploadNewSDK,
};
