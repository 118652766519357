import React from 'react';
import { AuthService } from '../../api';
import { AuthUser} from '../../Model/AuthUser';
import Input from '../../Components/Input'
import Button from '../../Components/Button'
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import {emailIsValid, phoneNumberIsValid} from '../../utils/validations'
import { useNotification } from '../../Components/Notification';

function AccountDetails() {
    const { user } = useAuth<AuthUser>();
    const [newUser, setNewUser] = React.useState(user);
    const [reqError, setReqError] = React.useState('');
    const notification = useNotification();
    const [isLoading, setIsLoading] = React.useState(false)


    const getData = () => {
            AuthService.GetUser(user.id).then((res)=>{
                setNewUser(res)
            })
    }

    const handleChange = (name: string, value: string | number) => {
        setNewUser({ ...newUser, [name]:value })
    }


    const handleUpdate = () => {
        const isValid = () => {
            return emailIsValid(newUser.email) && phoneNumberIsValid(newUser.phoneNumber) && newUser.firstName.length>0 && newUser.lastName.length>0 
        }

        if(isValid()){
            setIsLoading(true);
            AuthService.UpdateUser(newUser).then(()=>{
                notification({
                    type: 'success',
                    duration: 3000,
                    message: 'Information successfully changed!',
                })
                getData()
                setReqError("")
            }).catch((err)=>{
                const errorMessage = err.message ? err.message : "There was an error with the server. Please try again later."
                setReqError(errorMessage)  
            }).finally(()=>setIsLoading(false))
        } else {
            setReqError("Invalid input. Please check the form fields and correct any errors.")
        }
    }

    React.useEffect(()=>{
        getData()
    }, [])

    return (
      <>
        {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}
        <br/>
        <div className="bg-white p-6">
           <div className="flex justify-between">
                <div className="w-full">
                    <Input label="first name" 
                        className="w-10/12" 
                        placeholder={user.firstName} 
                        value={newUser.firstName} 
                        error={newUser.firstName.length===0}
                        onChange={(e) => handleChange('firstName', e.target.value)}/>
                </div> 

                <div className="w-full">
                    <Input label="last name" 
                        className="w-10/12" 
                        placeholder={user.lastName} 
                        value={newUser.lastName} 
                        error={newUser.lastName.length===0}
                        onChange={(e) => handleChange('lastName', e.target.value)}/>
                    </div>
            </div>

            <div className="flex justify-between">
                <div className="w-full">
                    <Input label="email" 
                        className="w-10/12" 
                        placeholder={user.email} 
                        value={newUser.email} 
                        type="email"
                        error={!emailIsValid(newUser.email)}
                        onChange={(e) => handleChange('email', e.target.value)}/>
                </div> 
                <div className="w-full">
                    <Input label="phone number" 
                        className="w-10/12"
                        placeholder={user.phoneNumber} 
                        value={newUser.phoneNumber} 
                        type="phone"
                        error={!phoneNumberIsValid(newUser.phoneNumber)}
                        onChange={(e) => handleChange('phoneNumber', e.target.value)}/></div>
            </div>
        <div>
            <Button className="bg-primary text-white mt-4" onClick={handleUpdate} loading={isLoading}>Update</Button>
        </div>
        </div>


        

      </>
    );
  };
  
export default AccountDetails;