import VendexActions from './VendexAction';

export type VendexImageMeta = {
    source: string;
    type: 'STATIC' | 'DYNAMIC';
};

export type VendexVideoMeta = {
    source: string;
    type: 'STATIC' | 'DYNAMIC';
    muted: boolean;
    autoplay: boolean;
    loop: boolean;
};

export type VendexTextMeta = {
    value: string;
    type: 'STATIC' | 'DYNAMIC' | 'SYSTEM_MESSAGE';
};

export type VendexTimerMeta = {
    showCountdown?: boolean;
    duration: number;
    type: 'IDLE' | 'STRICT';
};

export type VendexInputMeta = {
    name: string;
    type: 'text' | 'number' | 'date' | 'password';
    placeholder?: string;
};

export type VendexMultilineMeta = {
    name: string;
    placeholder?: string;
};

export type VendexCheckboxMeta = {
    name: string;
    checkedImage?: string;
    uncheckedImage?: string;
    defaultCheck?: boolean;
};

export type VendexRadioMeta = {
    name: string;
    checkedImage?: string;
    uncheckedImage?: string;
    value: string;
    defaultCheck?: boolean;
};

export type VendexPopItMeta = {
    name: string;
    popItems: PopItem[];
    itemsInScreen: number;
};
export type PopItem = {
    source: string;
    popSource?: string;
    width: number;
    height: number;
    point: number;
};

export type VendexQrMeta = {
    content: string;
    type: 'STATIC' | 'DYNAMIC';
};

export type VendexButtonMeta = string | undefined;
export default interface VendexElement {
    id: string;
    type: VendexElementType;
    metaData?:
        | VendexImageMeta
        | VendexTextMeta
        | VendexTimerMeta
        | VendexButtonMeta
        | VendexInputMeta
        | VendexMultilineMeta
        | VendexVideoMeta
        | VendexCheckboxMeta
        | VendexRadioMeta
        | VendexPopItMeta
        | VendexQrMeta;
    actions?: VendexActions[];
}

export enum VendexElementType {
    BUTTON = 'BUTTON',
    IMAGE = 'IMAGE',
    INPUT = 'INPUT',
    TIMER = 'TIMER',
    TEXT = 'TEXT',
    MULTILINE = 'MULTILINE',
    QRCODE = 'QRCODE',
    CHECK_BOX = 'CHECK_BOX',
    RADIO = 'RADIO',
    POP_IT_GAME = 'POP_IT_GAME',
    VIDEO = 'VIDEO',
}
