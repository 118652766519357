import React from 'react';
import Device from '../../Model/Device';
import DeviceModal from './DeviceModal';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import { DeviceService } from '../../api';
import DeviceList from './DeviceList';
import DeviceLicense from './DeviceLicense';
import ExperienceSelector from './ExperienceSelector';

function Devices(): JSX.Element {
    const [selectedDevice, setSelectedDevice] = React.useState<Device>();
    const [devices, setDevices] = React.useState<Device[]>();
    const [isEdit, setIsEdit] = React.useState(false);
    const [isExp, setIsExp] = React.useState(false);

    const getData = () => {
        DeviceService.GetDevices().then((response) => {
            setDevices(response);
        });
    };
    React.useEffect(() => {
        getData();
    }, []);

    const openEditDevice = (device: Device) => {
        setIsEdit(true);
        setSelectedDevice(device);
    };

    const openDeviceExp = (device: Device) => {
        setIsExp(true);
        setSelectedDevice(device);
    };

    return (
        <PortalWrapper>
            <DeviceLicense />
            {isEdit && selectedDevice && (
                <DeviceModal
                    onClose={() => {
                        setIsEdit(false);
                        setSelectedDevice(undefined);
                    }}
                    device={selectedDevice}
                    refreshData={getData}
                />
            )}
            {isExp && selectedDevice && (
                <ExperienceSelector
                    onClose={() => {
                        setIsExp(false);
                        setSelectedDevice(undefined);
                    }}
                    device={selectedDevice}
                    refreshData={getData}
                />
            )}
            {devices && (
                <DeviceList
                    data={devices}
                    openEditDevice={openEditDevice}
                    refreshData={getData}
                    openDeviceExperience={openDeviceExp}
                />
            )}
        </PortalWrapper>
    );
}

export default Devices;
