import React from 'react';
import Store from '../../Model/Store';
import { CompanyService, DeviceService } from '../../api';
import Modal from '../../Components/Modal';
import { CheckSquare, Square } from '../../Components/Icon';
import Device from '../../Model/Device';

interface Props {
    device: Device;
    refreshData: () => void;
    onCancel: () => void;
}

function StoreSelector({ device, refreshData, onCancel }: Props) {
    const [stores, setStores] = React.useState<Store[]>();
    const [selectedStore, setSelectedStore] = React.useState<Store>();

    React.useEffect(() => {
        CompanyService.GetStores(device.companyId as string).then(
            (response) => {
                setStores(response);
                const temp = response.find((el) => el.id === device.storeId);
                if (temp) {
                    setSelectedStore(temp);
                }
            },
        );
    }, []);

    const updateDevice = (id: string) => {
        DeviceService.UpdateDevice({
            ...device,
            storeId: id,
        }).then(() => {
            onCancel();
            refreshData();
        });
    };

    const handleSelection = (val: Store) => {
        if (val.id !== device.id) {
            setSelectedStore(val);
            updateDevice(val?.id as string);
        }
    };
    return (
        <Modal
            open
            close={onCancel}
            className="max-w-sm overflow-y-auto overflow-x-hidden"
            style={{ maxHeight: '80%' }}
        >
            <h4 className="text-xl font-semibold">ASSIGN STORE</h4>
            <div className="flex flex-col gap-3 mt-4 mb-16">
                {stores &&
                    stores.map((store) => (
                        <div
                            key={store.id}
                            className="flex gap-2 bg-gray-50 shadow-md rouned-md p-4 items-start hover:bg-gray-100"
                            role="button"
                            onClick={() => handleSelection(store)}
                            onKeyDown={(e) =>
                                e.key === 'Enter' && handleSelection(store)
                            }
                            tabIndex={0}
                        >
                            {selectedStore?.id === store.id ? (
                                <CheckSquare className="text-green-600 w-6 h-6" />
                            ) : (
                                <Square className="w-6 h-6" />
                            )}
                            <div>
                                <p className="font-semibold">{store.name}</p>
                                <p className="text-blue-600 text-xs">
                                    {store.timeZone}
                                </p>
                                <p className="text-gray-600">
                                    {store.location}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </Modal>
    );
}

export default StoreSelector;
