import clsx from 'clsx';
import React from 'react';

interface Props {
    selected: string;
    onChange: (value: string) => void;
    options: {
        label: string | React.ReactElement;
        value: string;
    }[];
}

function Tabs({ selected, onChange, options }: Props) {
    return (
        <div className="flex flex-wrap border-b">
            {options.map((tab) => (
                <div
                    key={tab.value}
                    className={clsx(
                        'px-2 md:px-6 lg:px-8 py-2 md:py-3 uppercase font-medium',
                        selected === tab.value
                            ? 'text-primary border-b-2 border-primary'
                            : 'opacity-50',
                    )}
                    onClick={() => onChange(tab.value)}
                    onKeyDown={(e) => e.key === 'Enter' && onChange(tab.value)}
                    tabIndex={0}
                    role="button"
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );
}

export default Tabs;
