const getTimezones = (): { value: string; label: string }[] => {
    return [
        {
            label: 'CDT',
            value: 'Central Standard Time',
        },
        {
            label: 'EST',
            value: 'Eastern Standard Time',
        },
        {
            label: 'PST',
            value: 'Pacific Standard Time',
        },
    ];
}

export default getTimezones;