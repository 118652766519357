import React from 'react';
import Product from '../../Model/Product';
import ProductModal from './ProductModal';
import Button from '../../Components/Button';
import { ShoppingBag } from '../../Components/Icon';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import Table from './ProductsList';
import { ProductService } from '../../api';

function Products(): JSX.Element {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState<Product>();
    const [productsData, setProductsData] = React.useState<Product[]>([]);

    const getData = () => {
        ProductService.GetAllProducts().then((response) => {
            setProductsData(response);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);
    const openEditProduct = (product: Product) => {
        setIsModalOpen(true);
        setSelectedProduct(product);
    };
    return (
        <PortalWrapper>
            <div className="flex gap-2">
                <Button
                    className="bg-secondary text-white flex gap-2 mb-6"
                    onClick={() => setIsModalOpen(true)}
                >
                    <ShoppingBag className="w-5 h-5" />
                    <span>Add New Product</span>
                </Button>
            </div>
            {isModalOpen && (
                <ProductModal
                    onClose={() => {
                        setIsModalOpen(false);
                        setSelectedProduct(undefined);
                    }}
                    product={selectedProduct}
                    refreshData={getData}
                />
            )}
            <Table
                tableData={productsData}
                openEditProduct={openEditProduct}
                refreshData={getData}
            />
        </PortalWrapper>
    );
}

export default Products;
