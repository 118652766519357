import React from 'react';
import Input from '../../../Components/Input';
import DimensionSelector from '../DimensionSelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import MediaSelector from '../MediaSelector';
import VendexElement, {
    VendexCheckboxMeta,
} from '../../../Model/VendexElement';
import Select from '../../../Components/Select';

type Props = {
    element: VendexElement;
};

function CheckboxDetail({ element }: Props) {
    const data = element.metaData as VendexCheckboxMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string | boolean) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Name*</p>
                <Input
                    onChange={(e) => {
                        handleDataChange('name', e.target.value);
                    }}
                    value={data.name}
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Default Checked</p>
                <Select
                    value={data.defaultCheck ? 'Yes' : 'No'}
                    onChange={(e) =>
                        handleDataChange(
                            'defaultCheck',
                            e.target.value === 'Yes',
                        )
                    }
                >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Select>
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Checked</p>
                <MediaSelector
                    url={data.checkedImage}
                    onChange={(value) =>
                        handleDataChange('checkedImage', value)
                    }
                    type="IMAGE"
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">UnChecked</p>
                <MediaSelector
                    url={data.uncheckedImage}
                    onChange={(value) =>
                        handleDataChange('uncheckedImage', value)
                    }
                    type="IMAGE"
                />
            </div>
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
        </>
    );
}

export default CheckboxDetail;
