import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import Button from '../../Components/Button';
import { Trello } from '../../Components/Icon';
import Experience from '../../Model/Experience';
import ExperienceModal from './ExperienceModal';
import { GetExperiences } from '../../api/experienceService';
import ExperienceList from './ExperienceList';

function Experiences() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<Experience>();
    const [exps, setExps] = React.useState<Experience[]>([]);

    const getData = () => {
        GetExperiences().then((response) => setExps(response));
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <PortalWrapper>
            <div className="flex gap-2 mb-8">
                <Button
                    className="bg-secondary text-white flex gap-2"
                    onClick={() => setIsModalOpen(true)}
                >
                    <Trello className="w-5 h-5" />
                    <span>Create New Experience</span>
                </Button>
            </div>
            <ExperienceList
                experienceData={exps}
                selectExperience={(exp) => {
                    setSelected(exp);
                    setIsModalOpen(true);
                }}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelected(undefined);
                }}
                refreshData={getData}
            />
            {isModalOpen && (
                <ExperienceModal
                    onClose={() => {
                        setIsModalOpen(false);
                        setSelected(undefined);
                    }}
                    exp={selected}
                    refreshData={getData}
                />
            )}
        </PortalWrapper>
    );
}

export default Experiences;
