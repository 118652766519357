import React from 'react';
import clsx from 'clsx';
import { CheckSquare, Truck } from '../../Components/Icon';
import Inventory, { Channel, InventoryReq } from '../../Model/Inventory';
import { DeviceService } from '../../api';
import Button from '../../Components/Button';
import InventoryProductSelection from './InventorProductSelection';
import Checkbox from '../../Components/Checkbox';
import InventoryChannelDetail from './InventoryChannelDetail';
import { useNotification } from '../../Components/Notification';
import Tooltip from '../../Components/Tooltip';
import { useConfirmation } from '../../Components/ConfirmationDialog';

interface Props {
    serialNumber: string;
}
function DeviceInventory({ serialNumber }: Props) {
    const [inventory, setInventory] = React.useState<Inventory>();
    const notification = useNotification();
    const [selected, setSelected] = React.useState<
        (Channel & { rowIndex: number })[]
    >([]);
    const [isProductOpen, setProductOpen] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const confirmation = useConfirmation();

    const getData = () => {
        DeviceService.GetInventory(serialNumber).then((response) =>
            setInventory(response),
        );
    };

    const handleSaveChannel = (
        req: InventoryReq,
        rowIndex: number,
        colIndex: number,
    ) => {
        // selected values change
        const tempSel = [...selected];
        const index = tempSel.findIndex((el) => el.id === req.id);
        if (index !== -1) {
            tempSel[index] = {
                ...tempSel[index],
                inventoryCount: req.inventoryCount,
            };
            setSelected(tempSel);
        }
        if (inventory) {
            const tempInv = [...inventory];
            tempInv[rowIndex][colIndex] = {
                ...tempInv[rowIndex][colIndex],
                inventoryCount: req.inventoryCount,
            };
            setInventory(tempInv);
        }
        DeviceService.UpdateInventory([req]).catch(() =>
            notification({
                type: 'error',
                message: 'error updating inventory',
            }),
        );
    };

    React.useEffect(() => {
        getData();
    }, [serialNumber]);

    const handleRowSelection = (index: number) => {
        let temp = [...selected];
        temp = temp.filter((el) => el.rowIndex !== index);
        const rowTemp = [...selectedRows];
        const existingRowItem = rowTemp.findIndex((el) => el === index);
        if (existingRowItem === -1 && inventory) {
            inventory[index]
                .filter((e) => e.isActive)
                .forEach((el) => {
                    temp.push({ ...el, rowIndex: index });
                });
            setSelectedRows([...selectedRows, index]);
        } else {
            rowTemp.splice(existingRowItem, 1);
            setSelectedRows(rowTemp);
        }
        setSelected(temp);
    };

    const handleClick = (item: Channel, rowIndex: number) => {
        const temp = [...selected];
        const index = temp.findIndex((el) => el.id === item.id);
        if (index === -1) {
            temp.push({ ...item, rowIndex });
        } else {
            temp.splice(index, 1);
        }
        // handle row selection
        const totalForRow = [...temp].filter((el) => el.rowIndex === rowIndex);
        const rowTemp = [...selectedRows];
        const rowIndexInSelectedRow = rowTemp.findIndex(
            (el) => el === rowIndex,
        );
        const rowIsAlreadySelected = rowIndexInSelectedRow !== -1;
        const isAllSelected = inventory
            ? inventory[rowIndex].length === totalForRow.length
            : false;
        if (rowIsAlreadySelected && !isAllSelected) {
            rowTemp.splice(rowIndexInSelectedRow, 1);
            setSelectedRows(rowTemp);
        }
        if (!rowIsAlreadySelected && isAllSelected) {
            setSelectedRows([...rowTemp, rowIndex]);
        }
        setSelected(temp);
    };

    const hasElement = (channel: Channel) => {
        return selected.findIndex((sel) => sel.id === channel.id) !== -1;
    };

    const isRowSelected = (index: number) => {
        return selectedRows.includes(index);
    };

    const handleRestockClicked = () => {
        confirmation({
            title: 'Confirm Restocking',
            message: 'Are you sure you want to restock this device?',
            onConfirm: () => {
                DeviceService.RestockInventory(serialNumber)
                    .then(() => {
                        getData();
                    })
                    .catch(() =>
                        notification({
                            type: 'error',
                            duration: 6000,
                            message: 'error restocking device',
                        }),
                    );
            },
        });
    };

    return (
        <div className="w-full shadow-md bg-white rounded-t-md">
            <div className="flex gap-2 px-4 py-3 rounded-t-md items-center bg-white/50 border-t border-b border-gray-200">
                <div className="flex-grow flex gap-2">
                    <Truck className="w-6 h-6" />
                    <h4 className="text-lg">Device Inventory</h4>
                </div>
                <Tooltip
                    text="This updates each inventory channel with the maximum allowed for the product associated with that channel"
                    className="max-w-xs"
                >
                    <Button
                        className="bg-secondary text-primary py-1 text-white"
                        onClick={handleRestockClicked}
                    >
                        Restock Inventory
                    </Button>
                </Tooltip>
                {selected.length > 0 && (
                    <Button
                        className="bg-white text-primary py-1"
                        onClick={() => setProductOpen(true)}
                    >
                        Assign New Product
                    </Button>
                )}
            </div>

            <div
                className="bg-white overflow-y-auto flex flex-col gap-3 p-4"
                style={{ maxHeight: '640px' }}
            >
                {inventory &&
                    inventory.length > 0 &&
                    inventory.map((el, rowIndex) => (
                        <div
                            key={`${rowIndex.toString()}`}
                            className="border-b-8 border-gray-600 pb-3 last-of-type:border-0 flex gap-4 items-center"
                        >
                            <Checkbox
                                className="w-8 h-8"
                                onChange={() => handleRowSelection(rowIndex)}
                                checked={isRowSelected(rowIndex)}
                            />
                            <div className="flex gap-3 flex-wrap border-l-2 border-gray-200 pl-4">
                                {el.map((channel, colIndex) => (
                                    <div
                                        key={channel.identifier}
                                        className={clsx(
                                            'w-40 relative shadow-lg bg-gray-50 rounded-lg h-32',
                                            channel.isActive &&
                                                'hover:bg-gray-100',
                                            !channel.isActive &&
                                                channel.product &&
                                                'bg-yellow-100',
                                            !channel.isActive &&
                                                !channel.product &&
                                                'bg-red-100',
                                        )}
                                        onClick={() =>
                                            channel.isActive &&
                                            handleClick(channel, rowIndex)
                                        }
                                        tabIndex={0}
                                        onKeyDown={(e) =>
                                            e.key === 'Enter' &&
                                            channel.isActive &&
                                            handleClick(channel, rowIndex)
                                        }
                                        role="button"
                                    >
                                        <p className="absolute top-1 left-1 text-lg font-light z-10">
                                            {channel.identifier ?? '1A'}
                                        </p>
                                        {hasElement(channel) && (
                                            <CheckSquare className="absolute top-2 right-2 z-10 text-blue-600 w-4 h-4" />
                                        )}
                                        <InventoryChannelDetail
                                            channel={channel}
                                            handleSaveChannel={(req) =>
                                                handleSaveChannel(
                                                    req,
                                                    rowIndex,
                                                    colIndex,
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                {!inventory && <div>CHANNELS ARE NOT CONFIGURED</div>}
            </div>
            {isProductOpen && (
                <InventoryProductSelection
                    close={() => {
                        setProductOpen(false);
                        setSelected([]);
                        setSelectedRows([]);
                    }}
                    selected={selected}
                    refreshData={getData}
                />
            )}
        </div>
    );
}

export default DeviceInventory;
