import React from 'react';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal';
import Input from '../../Components/Input';
import { DeviceService } from '../../api';
import { Loader } from '../../Components/Icon';
import { isSerialNumberValid } from '../../utils/validations';

function DeviceLicense() {
    const [isLicense, setIsLicense] = React.useState(false);
    const [serialNumber, setSerialNumber] = React.useState('');
    const [license, setLicense] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const { user } = useAuth<AuthUser>();

    const generateLicense = () => {
        setLoading(true);
        DeviceService.GenerateLicense(serialNumber)
            .then((response) => setLicense(response))
            .finally(() => setLoading(false));
    };

    const closeModal = () => {
        setIsLicense(false);
        setSerialNumber('');
        setLicense('');
    };

    if (user.role === UserRole.SUPERADMIN) {
        return (
            <>
                <div className="mb-4">
                    <Button
                        className="bg-secondary text-white"
                        onClick={() => setIsLicense(true)}
                    >
                        GENERATE NEW LICENSE
                    </Button>
                </div>
                <Modal open={isLicense} close={closeModal} className="min-h-0">
                    <p className="text-xl font-semibold mb-4">DEVICE LICENSE</p>
                    {license.length !== 0 ? (
                        <>
                            <p className="text-gray-600">
                                Enter the key in the device screen to get
                                started.
                            </p>
                            <p className=" my-4 p-2 bg-slate-100 text-xl rounded-md">
                                {license}
                            </p>
                            <Button
                                className="bg-primary text-white float-right"
                                onClick={closeModal}
                            >
                                close
                            </Button>
                        </>
                    ) : (
                        <>
                            <Input
                                label="Device Serial number"
                                value={serialNumber}
                                onChange={(e) =>
                                    setSerialNumber(e.target.value)
                                }
                                helperText={
                                    !isSerialNumberValid(serialNumber)
                                        ? 'Enter a valid serial number'
                                        : ''
                                }
                            />
                            <Button
                                className="bg-primary text-white flex justify-center gap-2 float-right"
                                loading={loading}
                                onClick={generateLicense}
                                disabled={!isSerialNumberValid(serialNumber)}
                            >
                                {!loading ? (
                                    'Generate'
                                ) : (
                                    <>
                                        <Loader />
                                        <span>Generating</span>
                                    </>
                                )}
                            </Button>
                        </>
                    )}
                </Modal>
            </>
        );
    }
    return null;
}

export default DeviceLicense;
