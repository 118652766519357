/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import { BuilderActionType, BuilderContext } from './state';
import IconButton from '../../Components/IconButton';
import { Code, Edit3, Eye, Save } from '../../Components/Icon';
import View from '../../Model/View';
import { UpdateVendExp } from '../../api/experienceService';
import VendExp from '../../Model/VendExp';
import { useNotification } from '../../Components/Notification';
import useDebounce from '../../utils/useDebounce';

function Navigation() {
    const { state, dispatch } = React.useContext(BuilderContext);
    const notification = useNotification();
    const [initialized, setInitialized] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const debouncedState = useDebounce<VendExp | undefined>(
        state.experience,
        2000,
    );

    React.useEffect(() => {
        // apply changes to experience
        dispatch({
            type: BuilderActionType.APPLY_CHANGES,
        });
    }, [state.pages, state.scripts, state.styles]);

    React.useEffect(() => {
        // apply changes to pages
        dispatch({
            type: BuilderActionType.UPDATE_PAGE,
        });
    }, [state.previewPage]);

    const handleSave = () => {
        if (state.experience) {
            setSaving(true);
            UpdateVendExp(state.experience)
                .finally(() => setSaving(false))
                .catch(() =>
                    notification({
                        type: 'error',
                        duration: 500,
                        message: 'Error saving Experience',
                    }),
                );
        }
    };

    React.useEffect(() => {
        if (state.experience && !initialized && state.view !== View.Preview) {
            setInitialized(true);
        }
    }, [state.experience]);

    React.useEffect(() => {
        if (initialized) {
            handleSave();
        }
    }, [debouncedState]);

    const handleViewChange = (view: View) => {
        dispatch({
            type: BuilderActionType.SET_VIEW,
            payload: view,
        });
    };

    return (
        <div className="mb-4 shadow-lg py-2 pr-4 pl-16 fixed top-0 left-0 z-10 w-screen h-16">
            <div className="w-full max-w-9xl mx-auto flex gap-3 items-center">
                <Link to="/">
                    <img
                        src="/vendx-full.png"
                        alt="vendex logo"
                        className="w-8 h-6"
                    />
                </Link>
                <Link to="/">
                    <h1 className="text-2xl uppercase text-primary">Builder</h1>
                </Link>
                <div className="flex flex-grow gap-4 justify-end">
                    {!saving ? (
                        <IconButton onClick={handleSave}>
                            <Save />
                        </IconButton>
                    ) : (
                        <span className="text-blue-500">saving...</span>
                    )}
                    <IconButton onClick={() => handleViewChange(View.Preview)}>
                        <Eye
                            color={
                                state.view === View.Preview
                                    ? '#1a8fe3'
                                    : '#000000'
                            }
                        />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange(View.CODE)}>
                        <Code
                            className="view-icon"
                            color={
                                state.view === View.CODE ? '#1a8fe3' : '#000000'
                            }
                        />
                    </IconButton>
                    <IconButton onClick={() => handleViewChange(View.EDIT)}>
                        <Edit3
                            className="view-icon"
                            color={
                                state.view === View.EDIT ? '#1a8fe3' : '#000000'
                            }
                        />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}
export default Navigation;
