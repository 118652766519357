import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import { Download, Loader } from '../../Components/Icon';
import UploadButton from '../../Components/UploadButton';
import { ApplicationService } from '../../api';
import { useNotification } from '../../Components/Notification';
import Build, { SDK } from '../../Model/Build';
import { formatDate } from '../../utils/formatText';
import Tabs from '../../Components/Tabs';
import IconButton from '../../Components/IconButton';

function Applications() {
    const [loading, setLoading] = React.useState(false);
    const [builds, setBuilds] = React.useState<Build[]>([]);
    const [sdk, setSdk] = React.useState<SDK[]>([]);
    const [latestBuild, setLatestBuild] = React.useState<Build>();
    const [latestSDK, setLatestSDK] = React.useState<SDK>();
    const [selectedTab, setSelectedTabs] = React.useState('VIEWER');

    const notification = useNotification();

    const getData = () => {
        Promise.all([
            ApplicationService.GetAllBuilds(),
            ApplicationService.GetAllSDKs(),
        ]).then((response) => {
            setSdk(response[1]);
            setBuilds(response[0]);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        const temp = builds.find((el) => el.isLatest);
        setLatestBuild(temp);
    }, [builds]);

    React.useEffect(() => {
        const temp = sdk.find((el) => el.isLatest);
        setLatestSDK(temp);
    }, [sdk]);

    const handleUploadBuild = async (files: File[]) => {
        setLoading(true);
        ApplicationService.UploadNewBuild(files[0])
            .then(() => {
                getData();
                notification({
                    type: 'success',
                    message: 'upload success',
                    duration: 6000,
                });
            })
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'upload failed',
                    duration: 6000,
                }),
            )
            .finally(() => setLoading(false));
    };

    const handleUploadSDK = async (files: File[]) => {
        setLoading(true);
        ApplicationService.UploadNewSDK(files[0])
            .then(() => {
                getData();
                notification({
                    type: 'success',
                    message: 'upload success',
                    duration: 6000,
                });
            })
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'upload failed',
                    duration: 6000,
                }),
            )
            .finally(() => setLoading(false));
    };

    const handleDownloadBuild = (versionNumber: string) => {
        setLoading(true);
        ApplicationService.DownloadBuild(versionNumber)
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'download failed',
                    duration: 6000,
                }),
            )
            .finally(() => setLoading(false));
    };

    const handleDownloadSDK = (versionNumber: string) => {
        ApplicationService.DownloadSDK(versionNumber)
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'download failed',
                    duration: 6000,
                }),
            )
            .finally(() => setLoading(false));
    };

    return (
        <PortalWrapper>
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full bg-white/40 z-20 flex justify-center items-center">
                    <Loader className="w-12 h-12" />
                </div>
            )}
            <div className="flex flex-col md:flex-row gap-3">
                <UploadButton
                    className="w-full bg-secondary text-white my-4 flex gap-2 justify-center items-center"
                    onSelect={handleUploadBuild}
                >
                    UPLOAD NEW BUILD
                </UploadButton>
                <UploadButton
                    className="w-full bg-secondary text-white my-4 flex gap-2 justify-center items-center"
                    onSelect={handleUploadSDK}
                >
                    UPLOAD NEW SDK
                </UploadButton>
            </div>
            <div className="flex flex-col md:flex-row gap-3 bg-white/50 rounded-lg border border-gray-200 p-5">
                {latestBuild && (
                    <div
                        className="bg-white shadow-lg rounded-md flex gap-2 items-start p-4 flex-grow hover:bg-gray-100"
                        tabIndex={0}
                        role="button"
                        onClick={() =>
                            handleDownloadBuild(latestBuild.versionNumber)
                        }
                        onKeyDown={(e) =>
                            e.key === 'Enter' &&
                            handleDownloadBuild(latestBuild.versionNumber)
                        }
                    >
                        <Download />
                        <div>
                            <p className="text-xl font-semibold">VIEWER</p>
                            <p className="text-gray-600">
                                {latestBuild.versionNumber}
                            </p>
                            <p className="font-semibold text-xs mt-2">
                                Date Updated
                            </p>
                            <p className="text-xs">
                                {formatDate(latestBuild.dateCreated)}
                            </p>
                        </div>
                    </div>
                )}
                {latestSDK && (
                    <div
                        className="bg-white shadow-lg rounded-md flex gap-2 items-start p-4 flex-grow  hover:bg-gray-100"
                        tabIndex={0}
                        role="button"
                        onClick={() =>
                            handleDownloadSDK(latestSDK.versionNumber)
                        }
                        onKeyDown={(e) =>
                            e.key === 'Enter' &&
                            handleDownloadSDK(latestSDK.versionNumber)
                        }
                    >
                        <Download />
                        <div className="w-full">
                            <p className="text-xl font-semibold">VEND SDK</p>
                            <p className="text-gray-600">
                                {latestSDK.versionNumber}
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <Tabs
                selected={selectedTab}
                onChange={(value) => setSelectedTabs(value)}
                options={[
                    { label: 'VIEWER', value: 'VIEWER' },
                    { label: 'SDK', value: 'SDK' },
                ]}
            />
            <div className="flex flex-col gap-2 mt-4">
                {selectedTab === 'VIEWER' &&
                    builds.map((el) => (
                        <div
                            key={el.versionNumber}
                            className="bg-white/50 shadow-lg rounded-md p-2 flex gap-2 justify-between items-center"
                        >
                            <div>
                                <p className="font-semibold">
                                    {el.versionNumber}
                                </p>
                                <p>{formatDate(el.dateCreated)}</p>
                            </div>
                            <IconButton
                                btnSize="small"
                                onClick={() =>
                                    handleDownloadBuild(el.versionNumber)
                                }
                            >
                                <Download />
                            </IconButton>
                        </div>
                    ))}
                {selectedTab === 'SDK' &&
                    sdk.map((el) => (
                        <div
                            key={el.versionNumber}
                            className="bg-white/50 shadow-lg rounded-md p-2 flex gap-2 justify-between items-center"
                        >
                            <div>
                                <p className="font-semibold">
                                    {el.versionNumber}
                                </p>
                            </div>
                            <IconButton
                                btnSize="small"
                                onClick={() =>
                                    handleDownloadBuild(el.versionNumber)
                                }
                            >
                                <Download />
                            </IconButton>
                        </div>
                    ))}
            </div>
        </PortalWrapper>
    );
}

export default Applications;
