import clsx from 'clsx';
import React from 'react';

interface Props {
    name: string;
    url?: string;
    size?: 'small' | 'medium' | 'large';
}

function Thumbnail({ name, url, size = 'medium' }: Props) {
    return (
        <img
            src={
                url ||
                `https://ui-avatars.com/api/?name=${name}&rounded=true&size=128`
            }
            className={clsx(
                size === 'medium' && 'w-16 h-16',
                size === 'large' && 'w-24 h-24',
                size === 'small' && 'w-12 h-12',
                'rounded-full',
            )}
            alt="product thumbnail"
        />
    );
}

export default Thumbnail;
