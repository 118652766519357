import React from 'react';
import { BuilderActionType, BuilderContext } from './state';
import View from '../../Model/View';
import Select from '../../Components/Select';

function PageSelector() {
    const { pages, previewPage, view } = React.useContext(BuilderContext).state;
    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: BuilderActionType.SET_PREVIEW_PAGE,
            payload: e.target.value,
        });
    };

    if (view === View.Preview || pages.length === 0) {
        return null;
    }

    return (
        <Select
            onChange={handleChange}
            value={previewPage?.id}
            className="fixed top-3 left-1/2 -translate-x-1/2 bg-gray-100 z-30 max-w-sm border-primary"
        >
            <option value="">SELECT PAGE</option>
            {pages.map((el) => (
                <option key={el.id} value={el.id}>
                    {el.name}
                </option>
            ))}
        </Select>
    );
}

export default PageSelector;
