import React from 'react';
import { useParams } from 'react-router-dom';
import ExperienceDeviceItem from './ExperienceDeviceItem';
import Device from '../../Model/Device';
import { DeviceService, ExperienceService } from '../../api';

function ExperienceDevices() {
    const { id } = useParams<{ id: string }>();
    const [expDevices, setExpDevices] = React.useState<Device[]>([]);

    const getData = () => {
        ExperienceService.GetExperienceDevices(id as string).then((response) =>
            setExpDevices(response),
        );
    };

    React.useEffect(() => {
        getData();
    }, []);

    const updateDevice = (device: Device) => {
        DeviceService.UpdateDevice(device).then(() => getData());
    };

    return (
        <div className="relative mt-4">
            {expDevices.map((device) => (
                <ExperienceDeviceItem
                    device={device}
                    key={device.id}
                    lockDevice={(val) =>
                        updateDevice({ ...val, isLocked: true })
                    }
                    unlockDevice={(val) =>
                        updateDevice({ ...val, isLocked: false })
                    }
                    removeDevice={(val) =>
                        updateDevice({ ...val, experienceId: undefined })
                    }
                />
            ))}
        </div>
    );
}

export default ExperienceDevices;
