import instance from './instance';
import Product, { ProductReq } from '../Model/Product';

function createPayload(req: ProductReq): FormData {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(req)) {
        formData.append(key, value as string);
    }

    // If file exists, append it to the formData
    if (req.file) {
        formData.delete('photoPath');
    }

    return formData;
}

function GetAllProducts(): Promise<Product[]> {
    return instance.get(`/products`).then((response) => {
        return response.data;
    });
}

function CreateProduct(req: ProductReq): Promise<boolean> {
    const payload = createPayload(req);
    return instance.post(`/product`, payload).then((res) => {
        return res.data;
    });
}

function UpdateProduct(req: ProductReq): Promise<boolean> {
    const payload = createPayload(req);
    return instance.put(`/product`, payload).then((res) => {
        return res.data;
    });
}

function DeleteProduct(id: string): Promise<boolean> {
    return instance.delete(`/product/${id}`).then((response) => {
        return response.data;
    });
}

export { GetAllProducts, CreateProduct, UpdateProduct, DeleteProduct };
