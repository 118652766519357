import React from 'react';
import Input from '../../../Components/Input';
import ActionSelector from '../ActionSelector';
import DimensionSelector from '../DimensionSelector';
import MediaSelector from '../MediaSelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, { VendexImageMeta } from '../../../Model/VendexElement';
import VendexAction from '../../../Model/VendexAction';
import Select from '../../../Components/Select';

type Props = {
    element: VendexElement;
};

function ImageDetail({ element }: Props) {
    const data = element.metaData as VendexImageMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Type</p>
                <Select
                    onChange={(e) => {
                        handleDataChange('type', e.target.value);
                    }}
                    value={data.type}
                >
                    <option value="STATIC">STATIC</option>
                    <option value="DYNAMIC">DYNAMIC</option>
                </Select>
            </div>
            {data.type === 'STATIC' ? (
                <div className="flex gap-3 mb-3">
                    <p className="w-40 font-semibold">Source</p>
                    <MediaSelector
                        url={data.source}
                        onChange={(value) => handleDataChange('source', value)}
                        type="IMAGE"
                    />
                </div>
            ) : (
                <div className="flex gap-3 mb-3">
                    <p className="w-40 font-semibold">Source</p>
                    <Input
                        value={data.source}
                        onChange={(e) =>
                            handleDataChange('source', e.target.value)
                        }
                    />
                </div>
            )}
            <br />
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <ActionSelector
                actions={element.actions}
                onChange={handleActionChange}
            />
        </>
    );
}

export default ImageDetail;
