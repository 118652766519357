/* eslint-disable react/jsx-no-constructed-context-values */
import clsx from 'clsx';
import React from 'react';
import IconButton from '../IconButton';
import { X } from '../Icon';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

interface NotificationData {
    type: NotificationType;
    duration?: number;
    message: string;
}
const NotificationContext = React.createContext<NotificationContextType>({
    addNotification: () => {},
});

interface NotificationContextType {
    addNotification: (notification: NotificationData) => void;
}

function Notification({
    notification,
    onClose,
}: {
    notification: NotificationData;
    onClose: (notification: NotificationData) => void;
}) {
    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (notification.duration) {
            timeoutId = setTimeout(
                () => onClose(notification),
                notification.duration,
            );
        }
        return () => timeoutId && clearTimeout(timeoutId);
    }, [notification.duration]);

    const handleClose = () => onClose(notification);
    return (
        <div
            className={clsx(
                'py-2 px-3 shadow-xl rounded-lg max-h-24 overflow-hidden truncate max-w-md fixed z-[999] bottom-4 right-2 pr-6 text-sm font-light',
                notification.type === 'success' && 'bg-green-600 text-white',
                notification.type === 'info' && 'bg-blue-600 text-white',
                notification.type === 'warning' && 'bg-yellow-600 text-white',
                notification.type === 'error' && 'bg-red-600 text-white',
            )}
        >
            {notification.message}
            <IconButton
                className="absolute top-1 right-1"
                btnSize="small"
                onClick={handleClose}
            >
                <X />
            </IconButton>
        </div>
    );
}
function NotificationProvider({ children }: { children: React.ReactNode }) {
    const [notifications, setNotifications] = React.useState<
        NotificationData[]
    >([]);

    const addNotification = (notification: NotificationData) => {
        setNotifications([...notifications, notification]);
    };

    const removeNotification = (notification: NotificationData) => {
        setNotifications((ntfcns) => ntfcns.filter((n) => n !== notification));
    };

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            {children}
            {notifications.map((notification, index) => (
                <Notification
                    key={`${index.toString()} ${new Date().getMilliseconds()}`}
                    notification={notification}
                    onClose={() => removeNotification(notification)}
                />
            ))}
        </NotificationContext.Provider>
    );
}

const useNotification = () =>
    React.useContext(NotificationContext).addNotification;

export { NotificationProvider, useNotification };

export default Notification;
