import React from 'react';
import Modal from '../../Components/Modal';
import BackgroundSelector from './BackgroundSelector';
import DimensionSelector from './DimensionSelector';
import FontStyleSelector from './TypographySelector';
import { BuilderActionType, BuilderContext } from './state';

function KeyboardSettings() {
    const { keybaordOpen } = React.useContext(BuilderContext).state;
    const { dispatch } = React.useContext(BuilderContext);

    const handleClose = () => {
        dispatch({
            type: BuilderActionType.TOGGLE_KEYBOARD,
        });
    };

    if (!keybaordOpen) {
        return null;
    }
    return (
        <Modal open={keybaordOpen} close={handleClose} className="max-w-lg">
            <div className="p-4">
                <h4 className="text-xl font-semibold">KEYBOARD SETTINGS</h4>
                <br />
                <BackgroundSelector
                    selector="#SCREEN_KEYBOARD"
                    hasImageSelection={false}
                />
                <DimensionSelector selector="#SCREEN_KEYBOARD" />
                <FontStyleSelector
                    selector="#SCREEN_KEYBOARD"
                    hasAlignment={false}
                />
            </div>
        </Modal>
    );
}

export default KeyboardSettings;
