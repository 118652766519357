import { matchPath } from 'react-router-dom';
import SelectedRoute from '../SelectedRoute';
import TemplateRoute from '../TemplateRoute';

export default function getMatchingRoute(
    pathname: string,
    routes: TemplateRoute[],
): SelectedRoute | null {
    let match: SelectedRoute | null = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routes) {
        const temp = matchPath({ path: route.path }, pathname);
        if (temp) {
            match = {
                path: route.path,
                authRoles: route.authRoles,
                Icon: route.Icon,
                label: route.label,
                navigation: route.navigation,
            };
        }
    }
    return match;
}
