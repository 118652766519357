import React from 'react';
import Device from '../../Model/Device';
import { Lock, Thermometer } from '../../Components/Icon';

function DeviceFeatures({ device }: { device: Device }) {
    return (
        <div className="flex gap-2 mt-2">
            {device.isLocked && (
                <div className="flex gap-2">
                    <Lock className="text-blue-300 w-4 h-4" />
                    <p className="text-xs">Locked</p>
                </div>
            )}
            {device.hasRefrigeration && (
                <div className="flex gap-2">
                    <Thermometer className="text-blue-500 w-4 h-4" />
                    <p className="text-xs">Has Refridgeration</p>
                </div>
            )}
        </div>
    );
}

export default DeviceFeatures;
