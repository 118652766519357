/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useSortBy, useTable, CellProps } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Device, {
    DeviceHealthStatus,
    DeviceInventoryStatus,
} from '../../Model/Device';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import DeviceQuickActions from './DeviceQuickActions';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import DeviceInventoryHealth from './DeviceInventoryHealth';
import DeviceHealth from './DeviceHealth';

interface Props {
    data: Device[];
    refreshData: () => void;
    openEditDevice: (device: Device) => void;
    openDeviceExperience: (device: Device) => void;
}

function DeviceList({
    data,
    refreshData,
    openEditDevice,
    openDeviceExperience,
}: Props) {
    const navigate = useNavigate();
    const { user } = useAuth<AuthUser>();

    function GetDeviceIntro({ nickName, serialNumber }: Device) {
        return (
            <>
                {nickName && nickName.length > 0 && <p>{nickName}</p>}
                <p className="font-bold">{serialNumber}</p>
            </>
        );
    }

    function GetTrusteeInfo({
        companyName,
        storeName,
        storeLocation,
        isPurchased,
    }: Device) {
        return (
            <div>
                {user.role === UserRole.SUPERADMIN && isPurchased && (
                    <p className="text-blue-600 text-xs font-bold">PURCHASED</p>
                )}
                {user.role === UserRole.SUPERADMIN &&
                    !isPurchased &&
                    companyName && (
                        <p className="text-blue-600 text-xs font-bold">
                            LEASED
                        </p>
                    )}
                {user.role === UserRole.SUPERADMIN &&
                    companyName &&
                    companyName.length > 0 && <p>{companyName}</p>}
                {storeName && storeName.length > 0 && (
                    <p className="text-gray-600">{storeName}</p>
                )}
                {storeLocation && storeLocation.length > 0 && (
                    <p>{storeLocation}</p>
                )}
            </div>
        );
    }

    const columns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'name',
                accessor: 'nickName',
                Cell: ({ row }: CellProps<Device>) => {
                    return GetDeviceIntro(row.original);
                },
            },
            {
                Header: 'trustee',
                accessor: 'companyName',
                Cell: ({ row }: CellProps<Device>) => {
                    return GetTrusteeInfo(row.original);
                },
            },
            {
                Header: 'Inventory',
                accessor: 'inventoryStatus',
                Cell: ({ value }: CellProps<Device, DeviceInventoryStatus>) => {
                    return <DeviceInventoryHealth health={value} />;
                },
            },
            {
                Header: 'status',
                accessor: 'health',
                Cell: ({ value }: CellProps<Device, DeviceHealthStatus>) => {
                    return <DeviceHealth health={value} />;
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: ({ row }: CellProps<Device>) => {
                    return DeviceQuickActions({
                        device: row.original,
                        refreshData,
                        openEditDevice,
                        openDeviceExperience,
                    });
                },
            },
        ];

        return baseColumns;
    }, [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy);

    if (data.length === 0) {
        return <p className="text-xl text-gray-600">No Devices found</p>;
    }
    return (
        <table {...getTableProps()} className="w-full">
            <thead className="shadow-md">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                {column.render('Header')}
                                <SortingIcon
                                    isSorted={column.isSorted}
                                    isSortedDesc={column.isSortedDesc}
                                />
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className="bg-white" {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className="hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                            onClick={() => {
                                navigate(`/devices/${row.original.id}`);
                            }}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        className="text-center py-3 border-r border-r-gray-200 px-2"
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default DeviceList;
