import React from 'react';
import IconButton from '../IconButton';
import {
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
} from '../Icon';
import Input from '../Input';
import Select from '../Select';

interface Props {
    total: number;
    onChange: (value: { page: number; pageSize: number }) => void;
    state: { page: number; pageSize: number };
}

function Pagination({ total, onChange, state }: Props) {
    const [pageInput, setPageInput] = React.useState(``);

    React.useEffect(() => {
        setPageInput(`${state.page}`);
    }, [state]);

    const handlePageInputChange = (inputString: string) => {
        const value = parseInt(inputString, 10);
        if (
            !Number.isNaN(value) &&
            value <= Math.ceil(total / state.pageSize) &&
            value !== 0
        ) {
            onChange({ ...state, page: value });
        } else {
            setPageInput('');
        }
    };

    const handlePageChange = (val: number) => {
        onChange({ ...state, page: val });
    };

    const handlePageSizeChange = (val: string) => {
        onChange({
            page: 1,
            pageSize: Number(val),
        });
    };

    return (
        <div className="flex gap-3 bg-white/50 border border-gray-200 p-4">
            <div className="flex-grow flex items-center gap-1">
                <IconButton
                    btnSize="small"
                    onClick={() => handlePageChange(1)}
                    disabled={state.page === 1}
                >
                    <ChevronsLeft />
                </IconButton>
                <IconButton
                    btnSize="small"
                    onClick={() => handlePageChange(state.page - 1)}
                    disabled={state.page === 1}
                >
                    <ChevronLeft />
                </IconButton>
                <div className="px-3 hidden lg:flex justify-center items-center gap-2">
                    <p>Page</p>
                    <Input
                        className="-mt-3 -mb-3 w-16 p-1"
                        type="number"
                        value={pageInput}
                        onChange={(e) => handlePageInputChange(e.target.value)}
                    />
                    <p>of {Math.ceil(total / state.pageSize)}</p>
                </div>
                <IconButton
                    btnSize="small"
                    onClick={() => handlePageChange(state.page + 1)}
                    disabled={state.page === Math.ceil(total / state.pageSize)}
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    btnSize="small"
                    onClick={() =>
                        handlePageChange(Math.ceil(total / state.pageSize))
                    }
                    disabled={state.page === Math.ceil(total / state.pageSize)}
                >
                    <ChevronsRight />
                </IconButton>
                <div className="px-3 hidden lg:flex justify-center items-center gap-2">
                    <Select
                        value={state.pageSize}
                        className="-mt-3 -mb-3 w-14 p-1"
                        onChange={(e) => handlePageSizeChange(e.target.value)}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Select>
                    <p>items per page</p>
                </div>
            </div>
            <div>
                <p>
                    {state.pageSize * state.page - state.pageSize + 1}-
                    {state.pageSize * state.page > total
                        ? total
                        : state.pageSize * state.page}{' '}
                    of {total} items
                </p>
            </div>
        </div>
    );
}

export default Pagination;
