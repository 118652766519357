import React from 'react';
import { AnalyticsService } from '../../api';
import { ProductAnalytics, SessionAnalytics } from '../../Model/Analytics';
import ProductChart from '../Dashboard/ProductChart';
import Experience from '../../Model/Experience';
import DateChart from '../Dashboard/DateChart';

interface Props {
    experience: Experience;
}

function ExperienceAnalytics({ experience }: Props) {
    const [sessionDuration, setSessionDuration] = React.useState<string>();
    const [sessionStats, setSessionStats] = React.useState<SessionAnalytics>();
    const [prodAnalytics, setProdAnalytics] =
        React.useState<ProductAnalytics[]>();

    const getData = () => {
        AnalyticsService.GetAverageSession(experience.id).then((response) =>
            setSessionDuration(response),
        );
        AnalyticsService.GetSessionAnalytics({
            experienceId: experience.id,
        }).then((response) => setSessionStats(response));
        AnalyticsService.GetProductAnalytics({
            experienceId: experience.id,
        }).then((response) => setProdAnalytics(response));
    };

    React.useEffect(() => {
        getData();
    }, [experience]);

    return (
        <div>
            <div className="flex flex-wrap gap-4">
                <div className="rounded-xl bg-secondary text-white flex flex-col justify-center items-center p-2 w-48 h-48">
                    <p className="text-6xl font-bold">
                        {sessionDuration}
                        <span className="text-sm font-normal">sec</span>
                    </p>
                    <p>AVERAGE SESSION</p>
                </div>
                <div className="rounded-xl bg-white p-4 flex-grow">
                    <div className="border-b border-gray-200 mb-4 pb-2">
                        <p className="font-bold">TOTAL SESSION</p>
                        <p className="text-6xl text-blue-600">
                            {sessionStats?.total}
                        </p>
                    </div>
                    <div className="flex gap-8">
                        <div>
                            <p className="font-bold">VEND SUCCESS</p>
                            <p className="text-4xl text-green-600">
                                {sessionStats?.success}
                            </p>
                        </div>
                        <div>
                            <p className="font-bold">INCOMPLETE SESSION</p>
                            {sessionStats && (
                                <p className="text-4xl text-yellow-600">
                                    {(sessionStats?.total as number) -
                                        (sessionStats?.success as number)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                {prodAnalytics && <ProductChart data={prodAnalytics} />}
            </div>
            <DateChart
                filters={{
                    from: experience.dateCreated,
                    experienceId: experience.id,
                }}
            />
        </div>
    );
}

export default ExperienceAnalytics;
