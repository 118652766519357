import React from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import styles from './modal.module.css';
import IconButton from '../IconButton';

interface ModalProps extends React.HTMLProps<HTMLDialogElement> {
    open: boolean;
    close: () => void;
    children: React.ReactNode;
}

function Modal(props: ModalProps) {
    const { open, close, children, className, ...elementProps } = props;
    if (!open) return null;
    return createPortal(
        <div
            className="w-full h-full bg-white/80 fixed top-0 left-0 overflow-hidden flex items-center justify-center py-8 px-4 z-50"
            onClick={close}
            onKeyDown={(e) => e.key === 'Escape' && close()}
            role="none"
        >
            <dialog
                className={clsx(
                    styles.modal,
                    'm-0 p-3 lg:p-4',
                    className && className,
                )}
                {...elementProps}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onKeyDown={(e) => e.key === 'Escape' && close()}
                role="none"
                open
            >
                <IconButton
                    className="absolute top-1 right-1 md:top-2 md:right-2 xl:top-3 xl:right-3"
                    btnSize="medium"
                    onClick={close}
                    autoFocus
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </IconButton>
                {children}
            </dialog>
        </div>,
        document.body,
    );
}

export function ModalActions({ children }: { children: React.ReactNode }) {
    return (
        <div className="modal-actions -ml-4 -mb-4 -mr-4 bg-gray-100 flex justify-end gap-2 mt-4 rounded-b-sm py-2 px-3 lg:px-4">
            {children}
        </div>
    );
}

export default Modal;
