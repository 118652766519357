import React from 'react';
import Store from '../../Model/Store';
import { Clock, Edit, Trash2 } from '../../Components/Icon';
import IconButton from '../../Components/IconButton';

interface Props {
    editStore: () => void;
    store: Store;
    deleteStore: () => void;
}

function StoreCard({ store, editStore, deleteStore }: Props) {
    return (
        <div className="rounded-md bg-white p-4 flex gap-2">
            <div>
                <p className="text-xl uppercase">{store.name}</p>
                <p className="text-xs text-blue-500">{store.timeZone}</p>
                {store.location && store.location.length > 0 && (
                    <p className="text-gray-600">{store.location}</p>
                )}
                <div className="mt-4">
                    {store.schedule &&
                        store.schedule.map((hours) => (
                            <div
                                className="flex gap-2 items-center mt-2"
                                key={hours.day}
                            >
                                <Clock className="w-6 h-6 text-blue-600" />
                                <p className="font-bold">{hours.day}</p>
                                <p>
                                    {hours.openingTime} to {hours.closingTime}
                                </p>
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex flex-col gap-1 -mr-2">
                <IconButton onClick={editStore}>
                    <Edit className="w-4 h-4" />
                </IconButton>
                <IconButton onClick={deleteStore}>
                    <Trash2 className="text-red-600 w-4 h-4" />
                </IconButton>
            </div>
        </div>
    );
}

export default StoreCard;
