/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

function useResizable<T extends HTMLElement>(ref: React.RefObject<T>) {
    const isResizing = React.useRef(false);
    const startX = React.useRef(0);
    const startWidth = React.useRef(0);

    React.useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            isResizing.current = true;
            startX.current = event.clientX;
            startWidth.current = parseInt(
                window.getComputedStyle(ref.current!).width,
                10,
            );
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!isResizing.current) return;

            const newWidth = startWidth.current + (event.clientX - startX.current);
            // eslint-disable-next-line no-param-reassign
            ref.current!.style.width = `${newWidth}px`;
        };

        const handleMouseOver = (event: MouseEvent) => {
            const element = event.target as HTMLElement;
            const { clientX } = event;

            // Check if the mouse is near the right end of the element
            if (
                element.clientWidth - clientX < 10 &&
                element.clientWidth - clientX > 0
            ) {
                element.style.cursor = 'ew-resize';
            } else {
                element.style.cursor = 'auto';
            }
        };

        const handleMouseUp = () => {
            isResizing.current = false;
        };

        if (ref.current) {
            // Attach event listeners
            ref.current.addEventListener('mousemove', handleMouseOver);
            ref.current.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        // Clean up event listeners
        return () => {
            if (ref.current) {
                ref.current.removeEventListener('mousemove', handleMouseOver);
                ref.current.removeEventListener('mousedown', handleMouseDown);
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [ref]);

    return ref;
}

export default useResizable;
