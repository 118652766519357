import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import {
    DataFilter,
    ProductAnalytics,
    SessionAnalytics,
} from '../../Model/Analytics';
import { AnalyticsService } from '../../api';
import ProductChart from './ProductChart';
import VendChart from './VendChart';
import UserTurnover from './UserTurnover';
import DateChart from './DateChart';
import { formatDateForDateTimeInput } from '../../utils/formatText';
import getDateOneMonthBeforeToday from '../../utils/getOneMonthBeforeToday';
import Filter from './Filter';

function Dashboard() {
    const [prodAnalytics, setProdAnalytics] = React.useState<
        ProductAnalytics[]
    >([]);
    const [sessionAnalytics, setSessionAnalytics] =
        React.useState<SessionAnalytics>();

    const [filters, setFilters] = React.useState<DataFilter>({
        from: formatDateForDateTimeInput(getDateOneMonthBeforeToday()),
    });

    const getData = () => {
        Promise.all([
            AnalyticsService.GetProductAnalytics(filters),
            AnalyticsService.GetSessionAnalytics(filters),
        ]).then((response) => {
            setProdAnalytics(response[0]);
            setSessionAnalytics(response[1]);
        });
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    return (
        <PortalWrapper>
            <Filter
                onChange={(value: DataFilter) => setFilters(value)}
                filters={filters}
            />
            <div className="flex flex-col gap-4 lg:flex-row mt-8">
                <div className="flex flex-col md:flex-row gap-4 flex-grow">
                    {sessionAnalytics && (
                        <UserTurnover data={sessionAnalytics} />
                    )}
                    {sessionAnalytics && <VendChart data={sessionAnalytics} />}
                </div>
                <ProductChart data={prodAnalytics} />
            </div>
            <DateChart filters={filters} />
        </PortalWrapper>
    );
}

export default Dashboard;
