import React from 'react';
import Button from '../Button';

interface Props {
    onSelect: (files: File[]) => void;
    multiple?: boolean;
    className?: string;
    children: React.ReactNode;
}
function UploadButton({
    onSelect,
    multiple = false,
    className = '',
    children,
}: Props) {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onButtonClick = (): void => {
        inputRef.current?.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            onSelect(Array.from(event.target.files));
        }
    };

    return (
        <>
            <Button className={className} onClick={onButtonClick} type="button">
                {children}
            </Button>
            <input
                ref={inputRef}
                type="file"
                className="hidden"
                id="input-file-upload"
                multiple={multiple}
                onChange={handleChange}
            />
        </>
    );
}

export default UploadButton;
