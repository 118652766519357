import React from 'react';
import Input from '../../Components/Input';
import Select from '../../Components/Select';

interface Props {
    options: string[];
    defaultOption?: string;
    onChange: (name: string, value: string) => void;
    value?: string;
    name: string;
}

function NumberInput({
    value = '',
    options,
    onChange,
    defaultOption,
    name,
}: Props) {
    const [number, setNumber] = React.useState('');
    const [option, setOption] = React.useState('');

    React.useEffect(() => {
        const optionsPattern = options.join('|'); // Construct the pattern from the options array
        const regex = new RegExp(`^(\\d*\\.?\\d+)(${optionsPattern})`); // Construct the regular expression with the pattern
        const matches = value.match(regex); // Apply the regular expression to the value

        if (matches) {
            const extractedNumber = matches[1]; // Extract the number
            setNumber(extractedNumber);
            const extractedOption = matches[2]; // Extract the unit
            setOption(extractedOption);
        } else {
            setNumber('');
            setOption(option.length > 0 ? option : defaultOption ?? options[0]);
        }
    }, [value]);

    return (
        <div className="flex">
            <Input
                value={number}
                type="number"
                onChange={(e) => onChange(name, `${e.target.value}${option}`)}
                className="rounded-r-none border-r-0 focus:border-r"
            />
            <Select
                value={option}
                onChange={(e) => onChange(name, `${number}${e.target.value}`)}
                className="rounded-l-none border-l-0 focus:border-l px-0 w-10"
            >
                {options.map((el) => (
                    <option value={el} key={el}>
                        {el}
                    </option>
                ))}
            </Select>
        </div>
    );
}

export default NumberInput;
