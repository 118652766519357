import React from 'react';
import { DeviceInventoryStatus } from '../../Model/Device';

function DeviceInventoryHealth({ health }: { health: DeviceInventoryStatus }) {
    switch (health) {
        case DeviceInventoryStatus.INVENTORY_LOW:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-red-600 w-3 h-3 rounded-full" />
                    low inventory
                </p>
            );
        case DeviceInventoryStatus.INVENTORY_HEALTHY:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-green-600 w-3 h-3 rounded-full" />
                    inventory available
                </p>
            );
        case DeviceInventoryStatus.NOT_CONFIGURED:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-yellow-600 w-3 h-3 rounded-full" />
                    not configured
                </p>
            );
        case DeviceInventoryStatus.CONFIGURED_BUT_PRODUCT_NOT_MAPPED:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-yellow-600 w-3 h-3 rounded-full" />
                    needs mapping
                </p>
            );
        default:
            return null;
    }
}

export default DeviceInventoryHealth;
