import React from 'react';
import { ChevronDown, ChevronUp } from '../Icon';

type Props = {
    header: React.ReactNode;
    children: React.ReactNode;
    open: boolean;
    onToggle: (value: boolean) => void;
};

function Expandable({ header, children, open, onToggle }: Props) {
    return (
        <div className="shadow-md relative rounded-md mb-4 bg-white">
            <div
                onClick={() => onToggle(!open)}
                className="p-3 pr-8 bg-gray-50 cursor-pointer rounded-t-md"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => e.key === 'Enter' && onToggle(!open)}
            >
                {header}
            </div>
            {open ? (
                <ChevronUp className="z-10 absolute top-3 right-3 pointer-events-none" />
            ) : (
                <ChevronDown className="z-10 absolute top-3 right-3 pointer-events-none" />
            )}
            {open && <div className="p-3">{children}</div>}
        </div>
    );
}

export default Expandable;
