import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // If using React Router

const useQuery = (): Record<string, string> | undefined => {
    const location = useLocation(); // If using React Router
    const queryParams = new URLSearchParams(location.search);
    const [params, setParams] = useState<Record<string, string>>();

    useEffect(() => {
        const paramsObj: Record<string, string> = {};
        queryParams.forEach((value, key) => {
            paramsObj[key] = value;
        });
        setParams(paramsObj);
    }, [location.search]);

    return params;
};

export default useQuery;
