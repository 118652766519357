import React from 'react';
import DeviceTimeline from '../../Model/DeviceTimeline';
import { DeviceService } from '../../api';
import {
    Activity,
    Edit,
    Layout,
    Plus,
    Trash2,
    User,
} from '../../Components/Icon';
import IconButton from '../../Components/IconButton';
import DeviceLease from './DeviceLease';
import Tooltip from '../../Components/Tooltip';
import Device from '../../Model/Device';
import PurchaseDialog from './PurchaseDialog';
import isCurrentTime24HoursBefore from '../../utils/isCurrentTime24HoursBefore';
import isMaxDate from '../../utils/isMaxDate';
import Thumbnail from '../../Components/Thumbnail';

interface Props {
    device: Device;
    refreshData: () => void;
}
function DeviceTimelineHistory({ device, refreshData }: Props) {
    const [timelines, setTimelines] = React.useState<DeviceTimeline[]>([]);
    const [addOpen, setAddOpen] = React.useState(false);
    const [purchaseOpen, setPurchaseOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<DeviceTimeline>();

    const getData = () => {
        DeviceService.GetDeviceTimeline(device.id).then((response) => {
            setTimelines(response);
        });
    };

    const deleteLease = (leaseId: string) => {
        DeviceService.DeleteLease(leaseId).then(() => {
            getData();
            refreshData();
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className="w-full shadow-md bg-white rounded-t-md">
            <div className="flex gap-2 px-4 py-3 rounded-t-md items-center bg-white/50 border-t border-b border-gray-200">
                <div className="flex-grow flex gap-2">
                    <Activity className="w-6 h-6" />
                    <h4 className="text-lg">Device Timeline</h4>
                </div>
                {!device.isPurchased && (
                    <>
                        <Tooltip text="Mark as purchased">
                            <IconButton onClick={() => setPurchaseOpen(true)}>
                                <Layout />
                            </IconButton>
                        </Tooltip>
                        <Tooltip text="Create new lease timeline">
                            <IconButton onClick={() => setAddOpen(true)}>
                                <Plus />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>
            <div
                className="bg-white overflow-y-auto pb-8"
                style={{ maxHeight: '640px' }}
            >
                {timelines.map((timeline, index) => (
                    <div className="relative flex" key={`${index.toString()}`}>
                        <div
                            className="flex flex-col p-4 justify-between w-32"
                            style={{ maxWidth: '128px', minWidth: '128px' }}
                        >
                            {isMaxDate(timeline.to) ? (
                                <div>
                                    <p className="text-blue-600 font-bold text-md">
                                        PURCHASED
                                    </p>
                                    <p className="text-md">
                                        {new Date(
                                            timeline.from,
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <p className="text-md">
                                            {new Date(
                                                timeline.from,
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-md">
                                            {new Date(
                                                timeline.to,
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                        <div
                            className="py-4 pr-2 pl-6 relative flex-grow border-l-2 border-secondary"
                            style={{ minHeight: '120px' }}
                        >
                            <div className="absolute bottom-0 -left-7 bg-white">
                                <Thumbnail
                                    name={timeline.companyName ?? ''}
                                    url={timeline.companyLogo}
                                    size="small"
                                />
                            </div>
                            <div className="flex gap-3 justify-end mb-3">
                                <IconButton
                                    btnSize="small"
                                    onClick={() => {
                                        setAddOpen(true);
                                        setSelected(timeline);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                                {isCurrentTime24HoursBefore(timeline.from) && (
                                    <IconButton
                                        btnSize="small"
                                        className="text-red-600"
                                        onClick={() =>
                                            deleteLease(timeline.id as string)
                                        }
                                    >
                                        <Trash2 />
                                    </IconButton>
                                )}
                            </div>
                            <p className="font-bold text-md">
                                {timeline.companyName}
                            </p>

                            {timeline.representativeEmail && (
                                <div className="flex gap-3 mt-3">
                                    <User className="text-blue-600 w-5 h-5" />
                                    <div>
                                        <p className=" text-xs font-semibold">
                                            {timeline.representativeName}
                                        </p>
                                        <a
                                            href={`mailTo:${timeline.representativeEmail}`}
                                            target="__blank"
                                        >
                                            <p className="text-xs font-thin">
                                                {timeline.representativeEmail}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {!timeline.representativeEmail && (
                                <div>
                                    <p className="text-xm font-semibold text-red-600">
                                        SETUP REPRESENTATIVE CONTACT
                                    </p>
                                    <p className="text-xs font-thin italic">
                                        Notification about low inventory and
                                        device errors are sent to device
                                        representatives.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {addOpen && (
                <DeviceLease
                    onClose={() => {
                        setAddOpen(false);
                        setSelected(undefined);
                    }}
                    deviceId={device.id}
                    timeline={selected}
                    refreshData={() => {
                        getData();
                        refreshData();
                    }}
                />
            )}
            {purchaseOpen && (
                <PurchaseDialog
                    onClose={() => setPurchaseOpen(false)}
                    refreshData={() => {
                        getData();
                        refreshData();
                    }}
                    device={device}
                />
            )}
        </div>
    );
}

export default DeviceTimelineHistory;
