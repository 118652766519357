import React from 'react';
import Thumbnail from '../../Components/Thumbnail';
import { formatLocation } from '../../utils/formatText';
import { AlertCircle, Clock, Info, MapPin } from '../../Components/Icon';
import { DeviceDetailResp } from '../../Model/Device';
import Button from '../../Components/Button';
import StoreSelector from './StoreSelector';
import { CompanyService } from '../../api';
import Store from '../../Model/Store';

interface Props {
    device: DeviceDetailResp;
    refreshData: () => void;
}

function DeviceCompany({ device, refreshData }: Props) {
    const [openStoreSelector, setOpenStoreSelector] = React.useState(false);
    const [store, setStore] = React.useState<Store>();

    React.useEffect(() => {
        if (device.storeId) {
            CompanyService.GetStore(device.storeId).then((response) => {
                setStore(response);
            });
        }
    }, [device]);

    return (
        <>
            <div className="bg-white rounded-md p-4 flex-grow">
                {device.isPurchased && (
                    <p className="text-lg text-blue-600">PURCHASED DEVICE</p>
                )}

                {!device.isPurchased && device.companyName && (
                    <p className="text-lg text-blue-600">LEASED DEVICE</p>
                )}

                {!device.isPurchased && !device.companyName && (
                    <p className="text-lg text-blue-600">
                        CURRENTLY UNASSIGNED
                    </p>
                )}

                <div className="flex gap-2 mt-3">
                    {device.companyName && (
                        <Thumbnail
                            url={device.companyLogoUrl}
                            name={device.companyName}
                            size="medium"
                        />
                    )}
                    {device.companyName && (
                        <div>
                            <p className="font-semibold text-lg text-primary">
                                {device.companyName}
                            </p>
                            {device.companyAddressLine1 && (
                                <div className="flex flex-col gap-2 mt-2">
                                    <div className="flex gap-2">
                                        <MapPin className="w-6 h-6" />
                                        <p className="text-gray-700">
                                            {formatLocation(
                                                device.companyAddressLine1,
                                                device.companyAddressLine2 ??
                                                    '',
                                                device.companyCity ?? '',
                                                device.companyState ?? '',
                                                'US',
                                                device.companyZipCode ?? '',
                                            )}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {store && (
                    <div>
                        <hr className="my-3" />
                        <p className="font-bold text-lg">STORE/VENUE</p>
                        <p>{store.name}</p>
                        <p className="text-xs text-blue-500">
                            {store.timeZone}
                        </p>
                        {store.location && store.location.length > 0 && (
                            <p className="text-gray-600">{store.location}</p>
                        )}
                        <div className="mt-4 flex flex-wrap gap-2">
                            {store.schedule &&
                                store.schedule.map((hours) => (
                                    <div
                                        className="flex gap-2 items-center mt-2"
                                        key={hours.day}
                                    >
                                        <Clock className="w-6 h-6 text-blue-600" />
                                        <p className="font-bold">{hours.day}</p>
                                        <p>
                                            {hours.openingTime} to{' '}
                                            {hours.closingTime}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                {!device.companyId && (
                    <p className="flex items-center gap-2 mt-3 bg-blue-200 rounded p-3">
                        <Info className="text-blue-600 w-6 h-6" />
                        This device is available. Go to the timeline tab to
                        lease or sell the device.
                    </p>
                )}
                {!device.storeId && (
                    <p className="flex items-center gap-2 mt-3 bg-red-200 rounded p-3">
                        <AlertCircle className="text-red-600 w-6 h-6" />
                        Assign this device to a store. Analytics and alert
                        notifications are associated with that store.
                    </p>
                )}
                {device.companyId && (
                    <Button
                        className="bg-secondary text-white mt-5"
                        onClick={() => setOpenStoreSelector(true)}
                    >
                        UPDATE STORE
                    </Button>
                )}
            </div>
            {openStoreSelector && (
                <StoreSelector
                    device={device}
                    refreshData={refreshData}
                    onCancel={() => setOpenStoreSelector(false)}
                />
            )}
        </>
    );
}

export default DeviceCompany;
