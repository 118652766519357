/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { AuthUser } from '../../../Model/AuthUser';

interface Props {
    // minutes of inactivity that triggers a logout
    timeout?: number;
    signInWithToken: () => Promise<{ role: any }>;
    signOut?: Promise<boolean>;
    Loader: React.FC;
    authKey: string;
    children: React.ReactNode;
}

export const AuthContext = React.createContext<any>({
    user: { role: 'visitor' },
    setUser: () => {},
});

function AuthProvider({ Loader, signInWithToken, children, authKey }: Props) {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [authUser, setAuthUser] = React.useState<any>({ role: 'visitor' });

    React.useEffect(() => {
        async function fetchLogin() {
            const data = await signInWithToken();
            return data;
        }
        if (localStorage.getItem(authKey)) {
            fetchLogin()
                .then((data) => {
                    setAuthUser({ ...data, role: data.role ?? 'visitor' });
                })
                .finally(() => {
                    setIsInitialized(true);
                });
        } else {
            setIsInitialized(true);
        }
    }, []);

    if (!isInitialized) {
        return <Loader />;
    }
    return (
        <AuthContext.Provider
            value={{
                user: authUser,
                setUser: (user: AuthUser) => setAuthUser(user),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
