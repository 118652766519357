import React from 'react';
import clsx from 'clsx';
import Product from '../../Model/Product';
import { Channel, InventoryReq } from '../../Model/Inventory';
import Modal from '../../Components/Modal';
import { DeviceService, ProductService } from '../../api';
import Input from '../../Components/Input';
import Thumbnail from '../../Components/Thumbnail';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';

interface Props {
    selected: Channel[];
    close: () => void;
    refreshData: () => void;
}

function InventoryProductSelection({ selected, close, refreshData }: Props) {
    const [products, setProducts] = React.useState<Product[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState<Product[]>([]);
    const { user } = useAuth<AuthUser>();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        ProductService.GetAllProducts().then((response) => {
            setProducts(response);
            setFilteredData(response);
        });
    }, []);

    React.useEffect(() => {
        const temp = products.filter((el) => el.name.includes(searchText));
        setFilteredData([...temp]);
    }, [searchText]);

    const handleUpdate = (req: InventoryReq[]) => {
        setLoading(true);
        DeviceService.UpdateInventory(req)
            .then(() => {
                close();
                refreshData();
            })
            .finally(() => setLoading(false));
    };

    const handleProductSelection = (product: Product) => {
        const temp: InventoryReq[] = [];
        selected.forEach((el) => {
            temp.push({
                id: el.id,
                productId: product.id,
                isActive: true,
                inventoryCount: el.inventoryCount,
            });
        });
        handleUpdate(temp);
    };

    return (
        <Modal
            open
            close={close}
            className="max-w-sm z-50 overflow-y-auto min-h-0 relative pb-6"
            style={{ maxHeight: '80vh' }}
        >
            <p className="text-xl font-semibold mb-6">ASSIGN A PRODUCT</p>
            <Input
                placeholder="search by name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="mb-4"
            />
            {filteredData.map((el) => (
                <div
                    key={el.id}
                    className={clsx(
                        'shadow-md rounded-md p-2 flex gap-2',
                        loading
                            ? 'pointer-events-none'
                            : 'hover:bg-gray-300 cursor-pointer ',
                    )}
                    onClick={() => handleProductSelection(el)}
                    onKeyDown={(e) =>
                        e.key === 'Enter' && handleProductSelection(el)
                    }
                    tabIndex={0}
                    role="button"
                >
                    <Thumbnail url={el.thumbnail} name={el.name} />
                    <div>
                        <p className="font-semibold">{el.name}</p>
                        {user.role === UserRole.SUPERADMIN && (
                            <p className="text-xs">
                                <strong>Company: </strong>
                                {el.companyName}
                            </p>
                        )}
                        <p>{el.description}</p>
                        <p>${el.price}</p>
                    </div>
                </div>
            ))}
            {loading && (
                <p className="text-right absolute bottom-2 right-2">
                    ...saving
                </p>
            )}
        </Modal>
    );
}

export default InventoryProductSelection;
