import { DeviceType } from './VendexDevice';

type Device = {
    id: string;
    companyId?: string;
    companyName: string;
    type: DeviceType;
    nickName?: string;
    experienceId?: string;
    serialNumber: string;
    isPurchased: boolean;
    isLocked: boolean;
    isInTestMode: boolean;
    hasRefrigeration: boolean;
    dateUpdated: string;
    dateCreated: string;
    health: DeviceHealthStatus;
    storeId?: string;
    storeName?: string;
    storeLocation?: string;
    inventoryStatus: DeviceInventoryStatus;
};

export type DeviceDetailResp = Device & {
    companyLogoUrl?: string;
    companyCity?: string;
    companyAddressLine1?: string;
    companyAddressLine2?: string;
    companyState?: string;
    companyZipCode?: string;
    notes?: string;
};

export enum DeviceHealthStatus {
    ONLINE,
    OFFLINE,
    NOT_LOGGED,
}

export enum DeviceInventoryStatus {
    NOT_CONFIGURED,
    CONFIGURED_BUT_PRODUCT_NOT_MAPPED,
    INVENTORY_LOW,
    INVENTORY_HEALTHY,
}

export default Device;
