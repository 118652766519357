import React from 'react';
import { LogsFilter } from '../../Model/Analytics';
import Device from '../../Model/Device';
import Experience from '../../Model/Experience';
import { DeviceService, ExperienceService } from '../../api';
import { Download, Filter as FilterIcon } from '../../Components/Icon';
import Select from '../../Components/Select';
import Input from '../../Components/Input';
import { getListFromEnum } from '../../utils/builderUtils';
import { LOGSEVERITY } from '../../Model/DeviceLog';
import Button from '../../Components/Button';
import Checkbox from '../../Components/Checkbox';

interface Props {
    refreshData: () => void;
    downloadAsCsv: () => void;
    filters: LogsFilter;
    onChange: (value: LogsFilter) => void;
}

function Filter({ refreshData, downloadAsCsv, filters, onChange }: Props) {
    const [devices, setDevices] = React.useState<Device[]>([]);
    const [experiences, setExperiences] = React.useState<Experience[]>([]);
    const logSeverity = getListFromEnum(Object.values(LOGSEVERITY));
    const [autoRefresh, setAutoRefresh] = React.useState(false);

    const getData = () => {
        Promise.all([
            ExperienceService.GetExperiences(),
            DeviceService.GetDevices(),
        ]).then((response) => {
            setExperiences(response[0]);
            setDevices(response[1]);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        let intervalId: NodeJS.Timer | undefined;
        if (autoRefresh) {
            intervalId = setInterval(() => {
                refreshData();
            }, 5000);
        }

        if (!autoRefresh) {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [autoRefresh]);

    const handleChange = (name: string, value: string) => {
        let newValue: string | undefined | number = value;
        if (value.length === 0) {
            newValue = undefined;
        }

        if (name === 'severity' && value) {
            newValue = parseInt(value, 10);
        }
        onChange({ ...filters, [name]: newValue });
    };

    return (
        <div className="bg-white/50 rounded-lg border border-gray-200 p-5">
            <div className="flex gap-2 items-center">
                <FilterIcon className="w-5 h-5" />
                <p className="text-lg text-primary font-medium">FILTERS</p>
            </div>
            <hr className="my-2" />
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                <Select
                    label="Experience"
                    value={filters.experienceId ?? ''}
                    onChange={(e) =>
                        handleChange('experienceId', e.target.value)
                    }
                >
                    <option value="">ALL EXPERIENCE</option>
                    <>
                        {experiences.map((el) => (
                            <option value={el.id} key={el.id}>
                                {el.name}
                            </option>
                        ))}
                    </>
                </Select>
                <Select
                    label="Device"
                    value={filters.serialNumber ?? ''}
                    onChange={(e) =>
                        handleChange('serialNumber', e.target.value)
                    }
                >
                    <option value="">ALL DEVICES</option>
                    <>
                        {devices.map((el) => (
                            <option value={el.serialNumber} key={el.id}>
                                {el.serialNumber} | {el.nickName}
                            </option>
                        ))}
                    </>
                </Select>
                <Input
                    type="datetime-local"
                    label="FROM"
                    value={filters.from}
                    error={typeof filters.from === 'undefined'}
                    onChange={(e) => handleChange('from', e.target.value)}
                />
                <Input
                    type="datetime-local"
                    label="TO"
                    value={filters.to ?? ''}
                    onChange={(e) => handleChange('to', e.target.value)}
                />
                <div className="-mt-6">
                    <Select
                        label="SEVERITY"
                        value={filters.severity ?? ''}
                        onChange={(e) =>
                            handleChange('severity', e.target.value)
                        }
                    >
                        <option value="">ALL LOGS</option>
                        <>
                            {logSeverity.map((el) => (
                                <option value={el.value} key={el.value}>
                                    {el.label}
                                </option>
                            ))}
                        </>
                    </Select>
                </div>
                {downloadAsCsv && (
                    <Button
                        className="bg-secondary text-white flex gap-2 self-start mt-3"
                        onClick={() => downloadAsCsv()}
                    >
                        <Download className="w-4 h-4" /> <span>DOWNLOAD</span>
                    </Button>
                )}
                <div className="-mt-2">
                    <p className="text-xs font-semibold mb-2">AUTO REFRESH</p>
                    <Checkbox
                        checked={autoRefresh}
                        toggle
                        onChange={() => setAutoRefresh(!autoRefresh)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Filter;
