import React from 'react';
import getTimezones from '../../utils/getTimeZones';

const timezone = getTimezones();

function TimeZoneOptions() {
    return (
        <>
            <option value="">SELECT A TIMEZONE</option>
            {timezone.map((el) => (
                <option key={el.label} value={el.value}>
                    {el.label}
                </option>
            ))}
        </>
    );
}

export default TimeZoneOptions;
