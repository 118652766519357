import React from 'react';
import Button from '../../Components/Button';
import PageDetails from './PageDetails';
import PageElements from './PageElements';
import { BuilderActionType, BuilderContext } from './state';
import { PageType } from '../../Model/PageData';
import PageSelector from './PageSelector';

function Workspace() {
    const { dispatch, state } = React.useContext(BuilderContext);
    const { previewPage } = state;

    const handleRemove = () => {
        dispatch({
            type: BuilderActionType.DELETE_PREVIEW_PAGE,
        });
    };

    if (!previewPage) {
        return null;
    }
    return (
        <div
            className="p-4 flex flex-col relative overflow-x-hidden overflow-y-auto w-full"
            style={{
                maxWidth: '50%',
                minWidth: '540px',
            }}
        >
            <PageSelector />
            <PageDetails />
            <PageElements />
            {previewPage.id !== PageType.SCREEN_DEFAULT && (
                <Button
                    onClick={handleRemove}
                    className="bg-primary-light text-white"
                >
                    REMOVE PAGE
                </Button>
            )}
        </div>
    );
}
export default Workspace;
