type DeviceLog = {
    serialNumber: string;
    experienceId?: string;
    sessionId: string;
    sessionStart?: string;
    sessionEnd?: string;
    productId: string;
    dateCreated: string;
    description: string;
    storeId?: string;
    isTestData: boolean;
    localDateCreated: string;
    severity: LOGSEVERITY;
    logs: LogItem[];
};
export type DeviceLogGrid = {
    id: string;
    sessionId: string;
    description: string;
    severity: LOGSEVERITY;
    localDateCreated: string;
    isTestData: boolean;
    dateCreated: string;
};

type LogItem = {
    type: LOGTYPE;
    dateCreated: string;
    message?: string;
};

export enum LOGSEVERITY {
    CRITICAL,
    ERROR,
    WARNING,
    INFO,
}

export enum LOGTYPE {
    SESSION_START,
    SESSION_COMPLETE,
    USER_INTERACTION,
    DATA_CAPTURE,
    VEND_SUCCESS,
    VEND_ERROR,
    INVENTORY_ERROR,
    QR_ERROR,
    DEVICE_ERROR,
    DEVICE_LOG,
    SERIALPORT_COMMUNICATION,
}

export default DeviceLog;
