import React from 'react';
import Viewer from '@vendx/engine';
import { BuilderContext } from '../state';
import DeviceBoundry from './DeviceBoundry';
import '@vendx/engine/dist/main.css';
import VendExp from '../../../Model/VendExp';

function Emulator() {
    const { pages, scripts, previewPage, styles } =
        React.useContext(BuilderContext).state;
    const [refresh, setRefresh] = React.useState(false);

    const setToIdle = () => {
        if (!previewPage) {
            setTimeout(() => {
                window.RECEIVER({
                    type: 'CHANGE_PAGE',
                    payload: { page: 'SCREEN_IDLE' },
                } as any);
            }, 1000);
        }
    };
    React.useEffect(() => {
        // watch changes on builder to refresh
        if (!refresh) {
            setRefresh(true);
            setTimeout(() => {
                setRefresh(false);
            }, 500);
        }
        setToIdle();
    }, [previewPage, scripts]);

    const experience = React.useMemo(() => {
        return {
            pages,
            scripts,
            styles,
        } as VendExp;
    }, [pages, scripts, styles]);

    if (refresh) return null;

    return (
        <DeviceBoundry>
            <Viewer
                experience={experience}
                previewPageId={previewPage ? previewPage.id : undefined}
            />
        </DeviceBoundry>
    );
}

export default Emulator;
