import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, usePagination, useSortBy, useTable } from 'react-table';
import ExperienceSession from '../../Model/ExperienceSession';
import { formatTime } from '../../utils/formatText';
import { CheckCircle } from '../../Components/Icon';
import Pagination from '../../Components/Pagination';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import { AnalyticsService } from '../../api';

function ExperienceData() {
    const { id } = useParams<{ id: string }>();
    const [tableState, setTableState] = React.useState({
        page: 1,
        pageSize: 20,
    });
    const [total, setTotal] = React.useState(0);
    const [data, setData] = React.useState<ExperienceSession[]>([]);

    const getData = () => {
        AnalyticsService.GetExperienceData(
            id as string,
            tableState.page,
            tableState.pageSize,
        ).then((response) => {
            setData(response.items);
            setTotal(response.total);
        });
    };

    React.useEffect(() => {
        getData();
    }, [tableState]);

    const getCheck = (value: boolean) => {
        if (value)
            return (
                <CheckCircle className="text-green-600 w-4 h-4 block mx-auto" />
            );
        return '';
    };
    const getUserData = (value: string) => {
        if (value && JSON.parse(value)) {
            const userdata = JSON.parse(value);
            return (
                <>
                    {Object.keys(userdata).map((el) => (
                        <div key={el} className="text-left">
                            <p className="font-bold uppercase">{el}</p>
                            <p>{userdata[el]}</p>
                        </div>
                    ))}
                </>
            );
        }
        return '';
    };
    const memoizedData = React.useMemo(() => [...data], [data]);
    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Date',
                accessor: 'dateCreated',
                Cell: ({ value }: CellProps<ExperienceSession, string>) => {
                    return new Date(value).toLocaleDateString();
                },
            },
            {
                Header: 'Session Start',
                accessor: 'sessionStart',
                Cell: ({ value }: CellProps<ExperienceSession, string>) => {
                    return formatTime(value);
                },
            },
            {
                Header: 'Duration',
                accessor: 'sessionDuration',
                Cell: ({ value }: CellProps<ExperienceSession, number>) => {
                    return `${value} sec`;
                },
            },
            {
                Header: 'Data',
                accessor: 'data',
                Cell: ({ value }: CellProps<ExperienceSession, string>) => {
                    return getUserData(value);
                },
            },
            {
                Header: 'Completed',
                accessor: 'isCompleted',
                Cell: ({ value }: CellProps<ExperienceSession, boolean>) => {
                    return getCheck(value);
                },
            },
        ];
        return baseColumns;
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
        useTable(
            {
                columns: memoizedColumns,
                data: memoizedData,
            },
            useSortBy,
            usePagination,
        );

    return (
        <>
            <table {...getTableProps()} className="rounded w-full">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {column.render('Header')}
                                    <SortingIcon
                                        isSorted={column.isSorted}
                                        isSortedDesc={column.isSortedDesc}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white border-2px" {...getTableBodyProps()}>
                    {/* @ts-ignore */}
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="border-b border-gray-100"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="text-center py-3 border-r border-r-gray-200 px-2"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                total={total}
                onChange={(val) => setTableState(val)}
                state={tableState}
            />
        </>
    );
}

export default ExperienceData;
