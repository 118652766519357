import React from 'react';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Store, { StoreHours } from '../../Model/Store';
import Select from '../../Components/Select';
import { X } from '../../Components/Icon';
import { CompanyService } from '../../api';
import TimeZoneOptions from '../../Components/TimeZoneOptions';
import { isClosingTimeAfterOpeningTime } from '../../utils/validations';
import Modal, { ModalActions } from '../../Components/Modal';

interface Props {
    onCancel: () => void;
    store: Store;
    refreshData: () => void;
}

const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

function DailySchedule({
    hours,
    onChange,
    onRemove,
    availDates,
}: {
    hours: StoreHours;
    onChange: (hours: StoreHours) => void;
    onRemove: () => void;
    availDates: string[];
}) {
    return (
        <div className="flex">
            <Select
                value={hours.day}
                onChange={(e) =>
                    onChange({ ...hours, [e.target.name]: e.target.value })
                }
                name="day"
                className="border-r-0 rounded-tr-none rounded-br-none"
            >
                <option>Select a day</option>
                <option value={hours.day}>{hours.day}</option>
                {availDates.map((el) => (
                    <option value={el} key={el}>
                        {el}
                    </option>
                ))}
            </Select>
            <Input
                type="time"
                value={hours.openingTime}
                onChange={(e) =>
                    onChange({ ...hours, [e.target.name]: e.target.value })
                }
                name="openingTime"
                className="border-l-0 border-r-0 rounded-none"
            />
            <Input
                type="time"
                value={hours.closingTime}
                onChange={(e) =>
                    onChange({ ...hours, [e.target.name]: e.target.value })
                }
                name="closingTime"
                className="border-l-0 border-r-0 rounded-none"
            />
            <Button
                className="border-l-0 rounded-tl-none rounded-bl-none bg-red-500 text-white py-0 my-3 px-2"
                onClick={onRemove}
            >
                <X className="w-4 h-4" />
            </Button>
        </div>
    );
}

const getAvailDates = (details: Store) => {
    let includedDays: string[] = [];

    if (details.schedule) {
        days.forEach((day) => {
            if (details.schedule?.findIndex((el) => el.day === day) === -1) {
                includedDays.push(day);
            }
        });
    } else {
        includedDays = days;
    }
    return includedDays;
};
function StoreForm({ onCancel, store, refreshData }: Props) {
    const [details, setDetails] = React.useState(store);
    const [availDates, setAvailDates] = React.useState(getAvailDates(details));

    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const newAvailDates = getAvailDates(details);
        setAvailDates(newAvailDates);
    }, [details]);
    const isScheduleValid = () => {
        let isValid = true;
        if (details.schedule) {
            details.schedule.forEach((el) => {
                if (
                    el.closingTime.length === 0 ||
                    el.openingTime.length === 0 ||
                    el.day.length === 0 ||
                    !isClosingTimeAfterOpeningTime(
                        el.openingTime,
                        el.closingTime,
                    )
                ) {
                    isValid = false;
                }
            });
        }
        return isValid;
    };

    const isValid = () => {
        if (details.name.length === 0) {
            return false;
        }
        if (details.timeZone.length === 0) {
            return false;
        }
        if (!isScheduleValid()) {
            return false;
        }
        return true;
    };

    const handleSave = () => {
        if (isValid()) {
            setShowError(false);
            setLoading(true);
            const req = details.id
                ? CompanyService.UpdateStore(details)
                : CompanyService.CreateStore(details);
            req.then(() => {
                refreshData();
                onCancel();
            }).finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    const handleChange = (name: string, value: string) => {
        setDetails({ ...details, [name]: value });
    };

    const handleHoursChange = (value: StoreHours, index: number) => {
        const temp = details.schedule ? [...details.schedule] : [];
        temp[index] = value;
        setDetails({ ...details, schedule: temp });
    };

    const addHours = () => {
        const temp = details.schedule ? [...details.schedule] : [];
        temp.push({
            day: availDates[0],
            openingTime: '',
            closingTime: '',
        });
        setDetails({ ...details, schedule: temp });
    };

    const removeHours = (index: number) => {
        const temp = details.schedule ? [...details.schedule] : [];
        temp.splice(index, 1);

        setDetails({ ...details, schedule: temp });
    };

    return (
        <Modal
            open
            close={onCancel}
            className="max-w-2xl overflow-y-auto overflow-x-hidden"
            style={{ maxHeight: '80%' }}
        >
            <p className="text-xl">DETAILS</p>
            <div className="grid md:grid-cols-2 gap-4">
                <Input
                    label="Name"
                    value={details.name}
                    name="name"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    error={showError && details.name.length === 0}
                    required
                />
                <Select
                    label="Timezone"
                    value={details.timeZone}
                    disabled={!!details.id}
                    name="timeZone"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    error={showError && details.timeZone.length === 0}
                    required
                >
                    <TimeZoneOptions />
                </Select>
            </div>
            <Input label="Address" value={details.location} name="location" />
            <p className="text-xl">SCHEDULE</p>
            {showError && !isScheduleValid() && (
                <p className="text-xs text-red-600 mt-2">
                    Please provide a valid schedule.
                </p>
            )}
            {(!details.schedule || details.schedule.length < 7) && (
                <Button
                    className="bg-primary-light text-white py-1 my-2"
                    onClick={addHours}
                >
                    Add Hours
                </Button>
            )}
            <div className="flex flex-wrap gap-2">
                {details.schedule &&
                    details.schedule.map((el, index) => (
                        <DailySchedule
                            key={el.day}
                            hours={el}
                            onChange={(val) => handleHoursChange(val, index)}
                            availDates={availDates}
                            onRemove={() => removeHours(index)}
                        />
                    ))}
            </div>
            <ModalActions>
                <Button
                    onClick={onCancel}
                    className="bg-primary-light text-white"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    className="bg-primary-light text-white"
                    loading={loading}
                >
                    Save
                </Button>
            </ModalActions>
        </Modal>
    );
}
export default StoreForm;
