import React from 'react';
import Experience from '../../Model/Experience';
import IconButton from '../../Components/IconButton';
import { Edit, Trash2 } from '../../Components/Icon';
import { ExperienceService } from '../../api';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import { useNotification } from '../../Components/Notification';

type Props = {
    onClose: () => void;
    refreshData: () => void;
    exp: Experience;
    selectExperience: (exp: Experience) => void;
};

function ExperienceActions({
    onClose,
    refreshData,
    exp,
    selectExperience,
}: Props) {
    const confirmation = useConfirmation();
    const notification = useNotification();

    const handleDelete = () => {
        confirmation({
            title: 'Delete Experience',
            message:
                'Are you sure you want to delete this experience? All devices associated with this experience will be affected.',
            onConfirm: () => {
                ExperienceService.DeleteExperience(exp!.id)
                    .then(() => {
                        onClose();
                        refreshData();
                    })
                    .catch(() =>
                        notification({
                            type: 'error',
                            duration: 6000,
                            message: 'error deleting experience.',
                        }),
                    );
            },
        });
    };

    return (
        <div
            className="flex gap-3"
            role="none"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <IconButton
                onClick={() => selectExperience(exp as Experience)}
                btnSize="small"
            >
                <Edit />
            </IconButton>
            <IconButton
                onClick={handleDelete}
                className="text-red-600"
                btnSize="small"
            >
                <Trash2 />
            </IconButton>
        </div>
    );
}

export default ExperienceActions;
