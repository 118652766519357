import React from 'react';
import Input from '../../../Components/Input';
import ActionSelector from '../ActionSelector';
import BackgroundSelector from '../BackgroundSelector';
import DimensionSelector from '../DimensionSelector';
import TypographySelector from '../TypographySelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, { VendexButtonMeta } from '../../../Model/VendexElement';
import VendexAction from '../../../Model/VendexAction';

type Props = {
    element: VendexElement;
};

function ButtonDetail({ element }: Props) {
    const data = element.metaData as VendexButtonMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleTextChange = (value: string) => {
        handleChange({
            ...element,
            metaData: value,
        });
    };

    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Button Text</p>
                <Input
                    onChange={(e) => {
                        handleTextChange(e.target.value);
                    }}
                    value={data}
                />
            </div>
            <BackgroundSelector selector={`#${element.id}`} />
            <TypographySelector selector={`#${element.id}`} />
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <ActionSelector
                actions={element.actions}
                onChange={handleActionChange}
            />
        </>
    );
}

export default ButtonDetail;
