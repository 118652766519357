import React from 'react';
import { Menu } from '../../Icon';
import IconButton from '../../IconButton';
import NavItems from './NavItems';
import useAuth from '../../LayoutProvider/AuthProvider/useAuth';
import { AuthUser } from '../../../Model/AuthUser';
import Company from '../../../Model/Company';
import { CompanyService } from '../../../api';
import Thumbnail from '../../Thumbnail';

function Navigation() {
    const [showNav, setShowNav] = React.useState(false);
    const { user } = useAuth<AuthUser>();
    const [company, setCompany] = React.useState<Company | null>();

    React.useEffect(() => {
        if (user.companyId) {
            CompanyService.GetCompanyById(user.companyId)
                .then((response) => setCompany(response))
                .catch(() => setCompany(null));
        } else {
            setCompany(null);
        }
    }, [user]);

    React.useEffect(() => {
        const handleMenuClose = (e: any) => {
            if (e.target && e.target.id !== 'menu-button') {
                setShowNav(false);
            }
        };
        if (showNav) {
            document.addEventListener('click', (e) => handleMenuClose(e));
        } else {
            document.addEventListener('click', (e) => handleMenuClose(e));
        }
    }, [showNav]);
    return (
        <>
            {!showNav && (
                <div className="flex lg:hidden fixed left-3 top-5 z-50">
                    <IconButton
                        onClick={() => setShowNav(true)}
                        id="menu-button"
                    >
                        <Menu className="text-white" />
                    </IconButton>
                </div>
            )}
            <nav
                className={`${
                    showNav ? 'flex' : 'hidden'
                } z-50 fixed lg:flex lg:relative h-full bg-white shadow-md w-64 lg:w-52 xl:w-72 max-w-sm flex-col px-2`}
            >
                <div className="flex gap-2 items-center h-16 lg:h-20 mx-auto">
                    <img
                        src={`${process.env.PUBLIC_URL}/vendx-full.png`}
                        className="w-8 h-5"
                        alt="Vendex Logo"
                    />
                    <h1 className="text-primary text-2xl font-bold">VENDEX</h1>
                </div>
                <hr />
                <div className="flex-grow overflow-y-auto py-3 flex flex-col gap-4">
                    <NavItems />
                </div>
                <hr className="pb-3" />
                {company === null && (
                    <div className="flex flex-col gap-2 pb-4">
                        <img
                            src={`${process.env.PUBLIC_URL}/Incharged_Logo.png`}
                            className="w-auto h-10 mx-auto"
                            alt="Incharged logo"
                        />
                    </div>
                )}
                {company && (
                    <div className="flex gap-2 pb-4 items-center justify-center">
                        <Thumbnail
                            url={company.logoUrl}
                            name={company.name}
                            size="small"
                        />
                        <div className="flex grow">
                            <p className="text-primary uppercase font-bold">
                                {company.name}
                            </p>
                        </div>
                    </div>
                )}
            </nav>
        </>
    );
}

export default Navigation;
