import React from 'react';
import { ChevronDown, ChevronUp } from '../Icon';

type Props = {
    isSorted: boolean;
    isSortedDesc: boolean;
};

function SortingIcon({ isSorted, isSortedDesc }: Props) {
    if (isSorted && isSortedDesc) {
        return <ChevronDown className="w-4 h-4 ml-1 inline-block" />;
    }
    if (isSorted && !isSortedDesc) {
        return <ChevronUp className="w-4 h-4 ml-1 inline-block" />;
    }
    return null;
}

export default SortingIcon;
