import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Wrapper from '../../Components/Layout/Wrapper';
import { passwordAreEqual, passwordIsValid } from '../../utils/validations';
import { AuthService } from '../../api';
import useQuery from '../../utils/useQuery';

function ResetPassword() {
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPasword] = React.useState('');
    const [showError, setShowError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [reqError, setReqError] = React.useState('');
    const query = useQuery();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (
            passwordIsValid(password) &&
            passwordAreEqual(password, confirmPassword) &&
            query?.token
        ) {
            setLoading(true);
            AuthService.ResetForgottenPassword({
                password,
                token: query.token,
            })
                .then(() => {
                    setSuccess(true);
                })
                .catch((error) => setReqError(error.message))
                .finally(() => setLoading(false));
        } else {
            setShowError(false);
        }
    };

    return (
        <Wrapper>
            <h6 className="text-xl font-semibold">RESET PASSWORD</h6>
            {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}
            {!success ? (
                <>
                    <p className="text-lg text-gray-600 mt-4">
                        Enter a new password
                    </p>
                    <p className="text-sm text-gray-600 mt-4">
                        Password must be at least 8 characters in length and
                        must contain one lowercase, one uppercase letter, one
                        number and one special character
                    </p>
                    <form
                        className="mt-4"
                        onSubmit={handleSubmit}
                        onClick={() => setReqError('')}
                        role="none"
                    >
                        <Input
                            label="Password"
                            name="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            error={showError && !passwordIsValid(password)}
                            type="password"
                            required
                        />
                        <Input
                            label="Confirm Password"
                            name="confirmPassword"
                            onChange={(e) => setConfirmPasword(e.target.value)}
                            type="password"
                            error={
                                showError &&
                                !passwordAreEqual(password, confirmPassword)
                            }
                            required
                        />
                        <div className="flex justify-between gap-2">
                            <Link
                                to="/login"
                                className="italic text-gray-600 underline"
                            >
                                Back to Login
                            </Link>
                            <Button
                                type="submit"
                                className="bg-primary text-white mt-4 float-right"
                            >
                                RESET
                            </Button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <p className="text-lg text-gray-600 mt-4">
                        Your password was successfully reset!
                    </p>
                    <Link to="/login">
                        <Button
                            className="bg-primary text-white mt-4"
                            loading={loading}
                        >
                            BACK TO LOGIN
                        </Button>
                    </Link>
                </>
            )}
        </Wrapper>
    );
}

export default ResetPassword;
