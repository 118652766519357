/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal';
import { BuilderActionType, BuilderContext } from './state';
import { Media } from '../../Model/Media';
import Input from '../../Components/Input';
import Tabs from '../../Components/Tabs';
import { ExperienceService } from '../../api';
import { Loader } from '../../Components/Icon';

const extensions: { [key: string]: string[] } = {
    IMAGE: ['jpg', 'png', 'gif', 'jpeg'],
    FONT: ['otf', 'ttf'],
    VIDEO: ['mov', 'mp4'],
};
type MediaGallery = Media & { canPreview: boolean };

function MediaLibrary() {
    const { id } = useParams<{ id: string }>();
    const library = React.useContext(BuilderContext).state.mediaLibrary;
    const { dispatch } = React.useContext(BuilderContext);
    const [medias, setMedias] = React.useState<MediaGallery[]>([]);
    const [tab, setTab] = React.useState(library ? library.open : 'MEDIA');
    const [files, setFiles] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState(false);

    const getAssets = () => {
        ExperienceService.GetVendExpAssetsById(id as string).then(
            (response) => {
                let filesA: MediaGallery[] = [];
                const regex = /(?:\.([^.]+))?$/;
                response.forEach((el) => {
                    const ext = regex.exec(el.url)?.[1] ?? '';
                    filesA.push({
                        ...el,
                        canPreview:
                            extensions.IMAGE.includes(ext) ||
                            extensions.VIDEO.includes(ext),
                    });
                });
                if (library && library.type) {
                    filesA = filesA.filter((el) => {
                        const fileExts = extensions[library.type as string];
                        const ext = regex.exec(el.url)?.[1];
                        if (!ext) return false;
                        return fileExts.includes(ext);
                    });
                }
                setMedias(filesA);
            },
        );
    };
    React.useEffect(() => {
        if (library?.open) {
            getAssets();
            setFiles([]);
            setTab(library.open);
        }
    }, [library?.open]);

    const closeModal = () => {
        dispatch({
            type: BuilderActionType.SET_MEDIA_LIBRARY,
        });
    };

    const handleChange = (value: string) => {
        if (library?.onChange) {
            library.onChange(value);
            closeModal();
        }
    };
    const handleRemove = (index: number) => {
        setFiles((prevFiles) => prevFiles.filter((_file, i) => i !== index));
    };

    const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };

    const handleUpload = () => {
        setLoading(true);
        ExperienceService.UploadVendExpAssets(id as string, files)
            .then(() => {
                getAssets();
                setTab('MEDIA');
            })
            .finally(() => setLoading(false));
    };

    if (!library) {
        return null;
    }

    return (
        <Modal
            open={!!library}
            close={closeModal}
            className="max-w-4xl overflow-y-auto"
            style={{ maxHeight: '80vh' }}
        >
            <Tabs
                selected={tab}
                onChange={(value) => setTab(value as 'UPLOAD' | 'MEDIA')}
                options={[
                    { label: 'MEDIA', value: 'MEDIA' },
                    { label: 'UPLOAD', value: 'UPLOAD' },
                ]}
            />
            {tab === 'MEDIA' && (
                <div
                    className="flex flex-wrap gap-2 p-3 items-start justify-center"
                    style={{ minHeight: '240px' }}
                >
                    {medias.map((el, index) => (
                        <div
                            className={`border-primary border flex flex-col justify-between gap-2 overflow-hidden cursor-pointer hover:outline hover:object:scale-${
                                library.selected &&
                                el.url.includes(library.selected)
                                    ? ' border-2 border-primary'
                                    : ''
                            }`}
                            style={{ maxWidth: '120px' }}
                            key={`${index.toString()}`}
                            role="button"
                            onKeyDown={(e) =>
                                e.key === 'Enyter' && handleChange(el.url)
                            }
                            tabIndex={0}
                            onClick={() => handleChange(el.url)}
                        >
                            {el.canPreview && (
                                <object
                                    data={el.url}
                                    className="w-full pointer-events-none"
                                >
                                    {el.fileName}
                                </object>
                            )}
                            <p className="text-center text-xs text-gray-500 p-2">
                                {el.fileName}
                            </p>
                        </div>
                    ))}
                    {medias.length === 0 && (
                        <p className="text-center text-md">
                            No files of type {library.type} were found
                        </p>
                    )}
                </div>
            )}
            {tab === 'UPLOAD' && (
                <div
                    className="flex flex-col items-center p-3"
                    style={{ minHeight: '240px' }}
                >
                    <Input type="file" multiple onChange={handleAddFiles} />
                    <br />
                    {files.length > 0 && (
                        <p className="text-xs text-gray-500">
                            Click on the item to remove
                        </p>
                    )}
                    <div className="flex flex-wrap mt-4 gap-2 justify-center">
                        {files.map((file, index) => (
                            <div
                                key={`${index.toString()}`}
                                role="button"
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && handleRemove(index)
                                }
                                onClick={() => handleRemove(index)}
                                tabIndex={0}
                                className="border border-primary p-2 w-48 text-center relative cursor-pointer hover:bg-gray-200"
                            >
                                <p className="text-xs text-gray-500">
                                    {file.name}
                                </p>
                            </div>
                        ))}
                    </div>
                    {files.length > 0 && (
                        <Button
                            onClick={handleUpload}
                            className="mt-4 flex gap-2 bg-primary text-white"
                        >
                            {loading && <Loader />} Add Media
                        </Button>
                    )}
                </div>
            )}
        </Modal>
    );
}

export default MediaLibrary;
