import React from 'react';
import { Link } from 'react-router-dom';
import Company from '../../Model/Company';
import { MapPin } from '../../Components/Icon';
import { formatLocation } from '../../utils/formatText';

interface Props {
    data: Company;
}

function CompanyCard({ data }: Props) {
    return (
        <Link
            to={`/companies/${data.id}`}
            className="relative shadow-md w-60 p-4 bg-white rounded-md before:h-9 before:absolute before:bg-secondary before:w-full before:top-0 before:left-0 before:rounded-t-md before:opacity-60  hover:scale-105"
        >
            <div className="w-16 h-16 bg-white relative z-[1] flex items-center justify-center shadow-md rounded-full">
                <img
                    src={
                        data.logoUrl
                            ? data.logoUrl
                            : `https://ui-avatars.com/api/?name=${data.name}&rounded=true&size=128`
                    }
                    className="w-full h-full rounded-full"
                    alt="company logo"
                />
            </div>
            <div className="pt-3">
                <p className="font-semibold text-lg text-primary">
                    {data.name}
                </p>
                <div className="flex flex-col gap-2 mt-2">
                    <div className="flex gap-2">
                        <MapPin className="w-6 h-6" />
                        <p className="text-gray-700">
                            {formatLocation(
                                data.addressLine1,
                                data.addressLine2 ?? '',
                                data.city,
                                data.state,
                                'US',
                                data.zipCode,
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default CompanyCard;
