import React from 'react';
import { Channel, InventoryReq } from '../../Model/Inventory';
import Thumbnail from '../../Components/Thumbnail';
import { AlertCircle, X } from '../../Components/Icon';

interface Props {
    channel: Channel;
    handleSaveChannel: (req: InventoryReq) => void;
}

function InventoryChannelDetail({ channel, handleSaveChannel }: Props) {
    const [quantity, setQuantity] = React.useState(0);

    const getChannelStatus = (): string => {
        if (channel.isActive && channel.product) return 'ACTIVE_ASSIGNED';
        if (channel.isActive && !channel.product) return 'ACTIVE_UNASSIGNED';
        if (!channel.isActive && channel.product) return 'ERROR';
        return 'INACTIVE';
    };

    const channelStatus = getChannelStatus();

    React.useEffect(() => {
        if (channel.inventoryCount !== quantity) {
            setQuantity(channel.inventoryCount);
        }
    }, [channel]);

    const handleSubmit = () => {
        handleSaveChannel({
            id: channel.id,
            productId: channel.product ? channel.product.id : undefined,
            isActive: channel.isActive,
            inventoryCount: quantity,
        });
    };

    return (
        <>
            {channelStatus === 'ACTIVE_ASSIGNED' && (
                <>
                    <div className="w-full flex flex-col justify-center items-center gap-2">
                        <Thumbnail
                            url={channel.product?.thumbnail}
                            name={channel.product?.name ?? ''}
                        />
                        <p className="text-md font-semibold text-center">
                            {channel.product?.name}
                        </p>
                    </div>
                    <input
                        value={quantity === 0 ? '' : quantity}
                        placeholder="Enter quantity"
                        onChange={(e) =>
                            setQuantity(
                                !Number.isNaN(parseInt(e.target.value, 10))
                                    ? parseInt(e.target.value, 10)
                                    : 0,
                            )
                        }
                        onBlur={handleSubmit}
                        type="number"
                        onClick={(e) => e.stopPropagation()}
                        className="bg-gray-100 pl-3 border-t-2 border-gray-500 px-1 py-1 rounded-t-none rounded-b-lg absolute bottom-0 left-0 w-full"
                    />
                </>
            )}
            {channelStatus === 'ACTIVE_UNASSIGNED' && (
                <p className="text-md font-semibold text-center mt-12">
                    Assign Product
                </p>
            )}
            {channelStatus === 'ERROR' && (
                <AlertCircle className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-yellow-600 text-2xl" />
            )}
            {channelStatus === 'INACTIVE' && (
                <X className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-red-600 text-2xl" />
            )}
        </>
    );
}

export default InventoryChannelDetail;
