import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Wrapper from '../../Components/Layout/Wrapper';
import {
    passwordAreEqual,
    passwordIsValid,
    phoneNumberIsValid,
} from '../../utils/validations';
import { AuthService } from '../../api';

function Register() {
    const [user, setUser] = React.useState({
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
    });

    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [reqError, setReqError] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (
            passwordIsValid(user.password) &&
            passwordAreEqual(user.password, user.confirmPassword) &&
            user.firstName.length > 0 &&
            user.lastName.length > 0 &&
            phoneNumberIsValid(user.phoneNumber)
        ) {
            const token = window.location.search.split('?token=')[1];
            AuthService.SignupApi({ ...user, AuthenticationToken: token })
                .then(() => {
                    setSuccess(true);
                })
                .catch((error) => setReqError(error.message))
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    return (
        <Wrapper>
            <h6 className="text-xl font-semibold">REGISTER</h6>
            {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}
            {!success ? (
                <form className="mt-4" onSubmit={handleSubmit}>
                    <Input
                        label="First Name"
                        name="First Name"
                        onChange={(e) =>
                            setUser({ ...user, firstName: e.target.value })
                        }
                        required
                        error={showError && user.firstName.length === 0}
                    />
                    <Input
                        label="Last Name"
                        name="Last Name"
                        onChange={(e) =>
                            setUser({ ...user, lastName: e.target.value })
                        }
                        required
                        error={showError && user.lastName.length === 0}
                    />
                    <Input
                        label="Phone Number"
                        name="phoneNUmber"
                        onChange={(e) =>
                            setUser({ ...user, phoneNumber: e.target.value })
                        }
                        required
                        error={
                            showError && !phoneNumberIsValid(user.phoneNumber)
                        }
                    />
                    <Input
                        label="Password"
                        name="Password"
                        onChange={(e) =>
                            setUser({ ...user, password: e.target.value })
                        }
                        type="password"
                        error={showError && !passwordIsValid(user.password)}
                        required
                        helperText="Password must be at least 8 characters in length and
                        must contain one lowercase, one uppercase letter, one
                        number and one special character"
                    />
                    <Input
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        onChange={(e) =>
                            setUser({
                                ...user,
                                confirmPassword: e.target.value,
                            })
                        }
                        error={
                            showError &&
                            !passwordAreEqual(
                                user.password,
                                user.confirmPassword,
                            )
                        }
                        required
                    />
                    <Button
                        type="submit"
                        className="bg-primary text-white mt-4 float-right"
                        loading={loading}
                    >
                        REGISTER
                    </Button>
                </form>
            ) : (
                <>
                    <p className="text-lg text-gray-600 mt-4">
                        Thank you for signing up!
                    </p>
                    <Link to="/login">
                        <Button
                            className="bg-primary text-white mt-4"
                            loading={loading}
                        >
                            BACK TO LOGIN
                        </Button>
                    </Link>
                </>
            )}
        </Wrapper>
    );
}

export default Register;
