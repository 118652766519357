import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './AuthProvider';
import RouteProvider from './RouteProvider';
import TemplateConfig from './TemplateConfig';

interface Props {
    config: TemplateConfig;
}

function LayoutProvider({ config }: Props) {
    return (
        <BrowserRouter>
            <AuthProvider
                signInWithToken={config.signInWithToken}
                Loader={config.PageLoader}
                authKey={config.authTokenLocalStorageKey}
            >
                <RouteProvider
                    Loader={config.PageLoader}
                    routes={config.routes}
                    loginRoute={config.loginRoute}
                    PageNotFound={config.PageNotFound}
                    PageUnAuthorized={config.PageUnAuthorized}
                />
            </AuthProvider>
        </BrowserRouter>
    );
}

export default LayoutProvider;
