import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../../Components/IconButton';
import { ExternalLink, Lock, MinusCircle, Unlock } from '../../Components/Icon';
import Tooltip from '../../Components/Tooltip';
import Device from '../../Model/Device';
import { DeviceType } from '../../Model/VendexDevice';
import DeviceHealth from '../Devices/DeviceHealth';
import DeviceInventoryHealth from '../Devices/DeviceInventoryHealth';

interface Props {
    device: Device;
    lockDevice: (device: Device) => void;
    unlockDevice: (device: Device) => void;
    removeDevice: (device: Device) => void;
}

function ExperienceDeviceItem({
    device,
    lockDevice,
    unlockDevice,
    removeDevice,
}: Props) {
    return (
        <div className="py-2 px-4 border-b flex gap-2 items-center justify-between bg-white">
            <div className='w-64'>
                {device.nickName && device.nickName.length > 0 && (
                    <p className="text-gray-600">{device.nickName}</p>
                )}
                <p className="text-md font-semibold">
                    {device.serialNumber} ({DeviceType[device.type]})
                </p>
            </div>
            <div className="flex flex-grow gap-2 ml-4">
                {device.isLocked && (
                    <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                        <span className="bg-blue-600 w-3 h-3 rounded-full" />
                        locked
                    </p>
                )}
                <DeviceHealth health={device.health} />
                <DeviceInventoryHealth health={device.inventoryStatus} />
            </div>
            <div className="flex gap-3">
                {device.isLocked ? (
                    <Tooltip text="unlock">
                        <IconButton
                            btnSize="small"
                            onClick={() => unlockDevice(device)}
                        >
                            <Unlock />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip text="lock experience">
                        <IconButton
                            btnSize="small"
                            onClick={() => lockDevice(device)}
                        >
                            <Lock />
                        </IconButton>
                    </Tooltip>
                )}
                <Link to={`/devices/${device.id}`}>
                    <IconButton btnSize="small">
                        <ExternalLink />
                    </IconButton>
                </Link>
                <IconButton
                    btnSize="small"
                    className="text-red-600"
                    onClick={() => removeDevice(device)}
                >
                    <MinusCircle />
                </IconButton>
            </div>
        </div>
    );
}

export default ExperienceDeviceItem;
