import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SignInWithEmailPasswordApi } from '../../api/authService';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Wrapper from '../../Components/Layout/Wrapper';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { emailIsValid } from '../../utils/validations';

function Login() {
    const [showError, setShowError] = React.useState(false);
    const [login, setLogin] = React.useState({ email: '', password: '' });
    const [loading, setLoading] = React.useState(false);
    const [reqError, setReqError] = React.useState('');
    const navigate = useNavigate();
    const { setUser, user } = useAuth();
    const location = useLocation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (emailIsValid(login.email) && login.password.length > 0) {
            setLoading(true);
            SignInWithEmailPasswordApi(login.email, login.password)
                .then((res) => {
                    setUser(res);
                    navigate(location.state?.from ?? '/');
                })
                .catch((error) => setReqError(error.message))
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };
    if (user.role !== 'visitor') {
        return <Navigate to="/" />;
    }

    return (
        <Wrapper>
            <h6 className="text-xl font-semibold">LOGIN</h6>
            {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}
            <form
                className="mt-4"
                onSubmit={handleSubmit}
                onClick={() => setReqError('')}
                role="none"
            >
                <Input
                    label="Email Address"
                    name="Email Address"
                    onChange={(e) =>
                        setLogin({ ...login, email: e.target.value })
                    }
                    error={showError && !emailIsValid(login.email)}
                    required
                />
                <Input
                    label="Password"
                    type="password"
                    name="password"
                    onChange={(e) =>
                        setLogin({ ...login, password: e.target.value })
                    }
                    required
                    error={showError && login.password.length === 0}
                />
                <div className="flex justify-between gap-2">
                    <Link
                        to="/forgot-password"
                        className="italic text-gray-600 underline"
                    >
                        Frogot password?
                    </Link>
                    <Button
                        type="submit"
                        loading={loading}
                        className="bg-primary text-white mt-4 float-right"
                    >
                        LOGIN
                    </Button>
                </div>
            </form>
        </Wrapper>
    );
}

export default Login;
