import React from 'react';
import { SketchPicker } from 'react-color';
import Modal from '../../Components/Modal';
import Button from '../../Components/Button';

type Props = {
    color?: string;
    onChange: (value: string) => void;
};

function ColorSelector({ color = 'transparent', onChange }: Props) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                className="w-6 h-6 border-primary border rounded-none p-0"
                style={{ backgroundColor: color }}
            />
            {open && (
                <Modal
                    open={open}
                    close={() => setOpen(false)}
                    className="max-w-xs flex justify-center py-8"
                >
                    <SketchPicker
                        color={color}
                        onChangeComplete={(e: { hex: string }) => {
                            onChange(e.hex);
                            setOpen(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
}

export default ColorSelector;
