/* eslint-disable react/jsx-no-constructed-context-values */

import React from 'react';
import Modal, { ModalActions } from '../Modal';
import Button from '../Button';

interface ConfirmationData {
    title: string;
    message: string;
    onConfirm?: () => any;
}
const ConfirmationContext = React.createContext<ConfirmationContextType>({
    openConfirmation: () => {},
});

interface ConfirmationContextType {
    openConfirmation: (confirmation: ConfirmationData) => void;
}

function ConfirmationProvider({ children }: { children: React.ReactNode }) {
    const [confirmation, setConfirmation] = React.useState<ConfirmationData>();

    const openConfirmation = (value: ConfirmationData) => {
        setConfirmation(value);
    };

    const closeConfirmation = () => {
        setConfirmation(undefined);
    };

    return (
        <ConfirmationContext.Provider value={{ openConfirmation }}>
            {children}
            <Modal
                open={!!confirmation}
                close={closeConfirmation}
                className="max-w-sm min-h-0"
            >
                <h4 className="text-xl font-semibold mb-4">
                    {confirmation?.title}
                </h4>
                <p className="break-words overflow-hidden">
                    {confirmation?.message}
                </p>
                <ModalActions>
                    <Button
                        className="bg-primary-light text-white py-1"
                        onClick={closeConfirmation}
                    >
                        Cancel
                    </Button>
                    {confirmation?.onConfirm && (
                        <Button
                            className="bg-primary-light text-white py-1"
                            onClick={() => {
                                confirmation?.onConfirm?.();
                                closeConfirmation();
                            }}
                        >
                            Confirm
                        </Button>
                    )}
                </ModalActions>
            </Modal>
        </ConfirmationContext.Provider>
    );
}

const useConfirmation = () =>
    React.useContext(ConfirmationContext).openConfirmation;

export { ConfirmationProvider, useConfirmation };
