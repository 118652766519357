export enum DeviceType {
    BIG_VEND,
    MINI_VEND,
}
type VendexDevice = {
    width: number;
    height: number;
    type: DeviceType;
};
const MINI_VEND = {
    width: 1080,
    height: 1920,
    type: DeviceType.MINI_VEND,
};

const BIG_VEND = {
    width: 960,
    height: 1706,
    type: DeviceType.BIG_VEND,
};

export const devices = [MINI_VEND, BIG_VEND];
export default VendexDevice;
