import React from 'react';
import Device from '../../Model/Device';
import Modal from '../../Components/Modal';
import Input from '../../Components/Input';
import Checkbox from '../../Components/Checkbox';
import Button from '../../Components/Button';
import { DeviceService } from '../../api';
import { DeviceType } from '../../Model/VendexDevice';

interface Props {
    device: Device;
    onClose: () => void;
    refreshData: () => void;
}

function DeviceModal({ device, onClose, refreshData }: Props) {
    const [data, setData] = React.useState(device);
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = () => {
        setLoading(true);
        DeviceService.UpdateDevice(data)
            .then(() => {
                refreshData();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal close={onClose} open className="max-w-md">
            <p className="text-xl font-semibold primary">EDIT DEVICE</p>
            <br />
            <p className="text-primary font-semibold">Serial Number</p>
            <p className="text-gray-500">{device.serialNumber}</p>
            <p className="text-primary font-semibold">TYPE</p>
            <p className="text-gray-500">{DeviceType[device.type]}</p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <Input
                    label="Nick Name"
                    value={data.nickName}
                    onChange={(e) =>
                        setData({ ...data, nickName: e.target.value })
                    }
                />
                <Checkbox
                    label="Has Refrigeration"
                    checked={data.hasRefrigeration}
                    onChange={() =>
                        setData({
                            ...data,
                            hasRefrigeration: !data.hasRefrigeration,
                        })
                    }
                />
                <Checkbox
                    label="Is Locked"
                    checked={data.isLocked}
                    onChange={() =>
                        setData({
                            ...data,
                            isLocked: !data.isLocked,
                        })
                    }
                />
                <Checkbox
                    label="Test Mode"
                    checked={data.isInTestMode}
                    onChange={() =>
                        setData({
                            ...data,
                            isInTestMode: !data.isInTestMode,
                        })
                    }
                />
                <p className="text-xs italic text-gray-600">
                    *Updates to experiences are not propagated to locked devices
                </p>
                <div className="flex gap-2 justify-end mt-2">
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default DeviceModal;
