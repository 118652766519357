import instance from './instance';
import Company, { CompanyReq } from '../Model/Company';
import Store from '../Model/Store';

function createPayload(req: CompanyReq | Company, file?: File): FormData {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(req)) {
        formData.append(key, value);
    }

    // If file exists, append it to the formData
    if (file) {
        formData.append('file', file);
        formData.delete('companyLogoUrl');
    }

    return formData;
}

function CreateCompany(req: CompanyReq, file?: File): Promise<boolean> {
    const payload = createPayload(req, file);
    return instance.post(`/company`, payload).then((res) => {
        return res.data;
    });
}

function UpdateCompany(req: Company, file?: File): Promise<boolean> {
    const payload = createPayload(req, file);
    return instance.put(`/company`, payload).then((res) => {
        return res.data;
    });
}

function GetCompanies(): Promise<Company[]> {
    return instance.get('/companies').then((response) => {
        return response.data;
    });
}

function GetCompanyById(id: string): Promise<Company> {
    return instance.get(`/company?Id=${id}`).then((response) => {
        return response.data;
    });
}

function DeleteCompany(id: string): Promise<boolean> {
    return instance.delete(`/company?Id=${id}`).then((response) => {
        return response.data;
    });
}

function GetStores(companyId?: string): Promise<Store[]> {
    let reqUrl = `/stores`;
    if (companyId) {
        reqUrl += `?companyId=${companyId}`;
    }
    return instance.get(reqUrl).then((response) => {
        return response.data;
    });
}

function GetStore(storeId?: string): Promise<Store> {
    return instance.get(`/store/${storeId}`).then((response) => {
        return response.data;
    });
}

function CreateStore(store: Store): Promise<boolean> {
    return instance.post(`/store`, store).then((response) => {
        return response.data;
    });
}
function UpdateStore(store: Store): Promise<boolean> {
    return instance.put(`/store`, store).then((response) => {
        return response.data;
    });
}
function DeleteStore(storeId: string): Promise<boolean> {
    return instance.delete(`/store/${storeId}`).then((response) => {
        return response.data;
    });
}

export {
    CreateCompany,
    UpdateCompany,
    GetCompanies,
    DeleteCompany,
    GetCompanyById,
    CreateStore,
    UpdateStore,
    GetStores,
    DeleteStore,
    GetStore,
};
