import React from 'react';
import clsx from 'clsx';
import styles from './checkbox.module.css';

interface Props extends React.HTMLProps<HTMLInputElement> {
    label?: string;
    className?: string;
    toggle?: boolean;
}

const Checkbox = React.forwardRef(
    (props: Props, ref: React.LegacyRef<HTMLInputElement> | undefined) => {
        const { label, className, toggle, ...elementProps } = props;
        return (
            <div className="flex items-center mb-4">
                <input
                    className={clsx(
                        styles.checkbox,
                        toggle ? styles.toggle : styles.checkbox,
                        className && className,
                    )}
                    ref={ref}
                    {...elementProps}
                    type="checkbox"
                    value=""
                />
                {label && (
                    <label
                        htmlFor={elementProps.id}
                        className="ml-2 text-sm font-medium"
                    >
                        {label}
                    </label>
                )}
            </div>
        );
    },
);
export default Checkbox;
