import React from 'react';
import useRoute from '../../LayoutProvider/RouteProvider/useRoute';

function PageTitle() {
    const { selected: route } = useRoute();
    if (!route) return null;
    const { Icon, label } = route;
    return (
        <div className="text-white flex gap-2 items-center ml-7 lg:ml-0">
            {Icon && <Icon />}
            <h1 className="text-lg lg:text-xl">{label}</h1>
        </div>
    );
}

export default PageTitle;
