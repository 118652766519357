import React from 'react';
import { Link } from 'react-router-dom';
import Device from '../../Model/Device';
import IconButton from '../../Components/IconButton';
import { Edit, ExternalLink, Trash2, Trello } from '../../Components/Icon';
import { DeviceService } from '../../api';
import { useNotification } from '../../Components/Notification';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import Tooltip from '../../Components/Tooltip';

interface Props {
    device: Device;
    refreshData: () => void;
    openEditDevice: (device: Device) => void;
    openDeviceExperience: (device: Device) => void;
}

function DeviceQuickActions({
    device,
    refreshData,
    openEditDevice,
    openDeviceExperience,
}: Props) {
    const notification = useNotification();
    const confirmation = useConfirmation();
    const { user } = useAuth<AuthUser>();

    const handleDelete = () => {
        confirmation({
            title: 'Delete Device',
            message: 'Are you sure you want to delete this device?',
            onConfirm: () => {
                DeviceService.DeleteDevice(device.id)
                    .then(() => {
                        refreshData();
                    })
                    .catch(() =>
                        notification({
                            type: 'error',
                            duration: 6000,
                            message: 'error deleting device.',
                        }),
                    );
            },
        });
    };

    return (
        <div
            className="flex gap-3"
            role="none"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Tooltip text="Edit Device">
                <IconButton onClick={() => openEditDevice(device)} btnSize="small">
                    <Edit />
                </IconButton>
            </Tooltip>
            <Tooltip text="Device experience">
                <IconButton
                    onClick={() => openDeviceExperience(device)}
                    btnSize="small"
                >
                    <Trello />
                </IconButton>
            </Tooltip>
            {user.role === UserRole.SUPERADMIN && (
                <Tooltip text="Delete Device">
                    <IconButton
                    onClick={handleDelete}
                    className="text-red-600"
                    btnSize="small"
                    >
                    <Trash2 />
                    </IconButton>
                </Tooltip>
            )}
            {device.experienceId && (
                <Tooltip text="Preview experience">
                    <Link
                        to={`/experiences/${device.experienceId}/builder`}
                        target="_blank"
                    >
                        <IconButton btnSize="small">
                            <ExternalLink />
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
        </div>
    );
}

export default DeviceQuickActions;
