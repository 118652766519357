import { PopupPosition } from './PopupPosition';
import { PopupPositionStyle } from './PopupPositionStyle';

type Params = {
    popup: { width: number; height: number };
    windowEl: { width: number; height: number };
    anchor: {
        width: number;
        height: number;
        top: number;
        left: number;
        bottom: number;
        right: number;
    };
    position: PopupPosition;
    spacing?: number;
};

const calculatePosition = (props: Params): PopupPositionStyle => {
    const { anchor, popup, windowEl, position, spacing = 0 } = props;
    const halfAnchorWidth = anchor.width / 2;
    const halfPopupWidth = popup.width / 2;

    const positions = {
        top: {
            top: anchor.top - popup.height - spacing,
            left: anchor.left + halfAnchorWidth - halfPopupWidth,
        },
        bottom: {
            top: anchor.bottom + spacing,
            left: anchor.left + halfAnchorWidth - halfPopupWidth,
        },
        left: {
            top: anchor.top,
            left: anchor.left - popup.width - spacing,
        },
        right: {
            top: anchor.top,
            left: anchor.right + spacing,
        },
    };

    const positionStyle = positions[position];

    // prevent overflow on the top and left
    positionStyle.top = Math.max(positionStyle.top, 8);
    positionStyle.left = Math.max(positionStyle.left, 8);

    const bottom = positionStyle.top + popup.height;
    const right = positionStyle.left + popup.width;

    // prevent right overflow
    if (right > windowEl.width - 8) {
        positionStyle.left = windowEl.width - popup.width - 8;
    }
    // prevent bottom overflow;
    if (bottom > windowEl.height - 8) {
        positionStyle.top = windowEl.height - popup.height - 8;
    }

    return positionStyle;
};

export default calculatePosition;
