import React from 'react';
import Input from '../../../Components/Input';
import ActionSelector from '../ActionSelector';
import DimensionSelector from '../DimensionSelector';
import TypographySelector from '../TypographySelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, { VendexTextMeta } from '../../../Model/VendexElement';
import VendexAction from '../../../Model/VendexAction';
import Select from '../../../Components/Select';

type Props = {
    element: VendexElement;
};

function TextDetail({ element }: Props) {
    const data = element.metaData as VendexTextMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Type</p>
                <Select
                    onChange={(e) => {
                        handleDataChange('type', e.target.value);
                    }}
                    value={data.type}
                >
                    <option value="STATIC">STATIC</option>
                    <option value="DYNAMIC">DYNAMIC</option>
                    <option value="SYSTEM_MESSAGE">SYSTEM_MESSAGE</option>
                </Select>
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">
                    {data.type === 'SYSTEM_MESSAGE'
                        ? 'Default message'
                        : 'Value'}
                </p>
                <Input
                    value={data.value}
                    onChange={(e) => handleDataChange('value', e.target.value)}
                />
            </div>
            <TypographySelector selector={`#${element.id}`} />
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <ActionSelector
                actions={element.actions}
                onChange={handleActionChange}
            />
        </>
    );
}

export default TextDetail;
