'use client';

import clsx from 'clsx';
import React from 'react';
import styles from './button.module.css';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    /** button loading state. The button is disabled during this stage */
    loading?: boolean;
    type?: 'button' | 'reset' | 'submit';
}

const Button = React.forwardRef(
    (props: Props, ref: React.LegacyRef<HTMLButtonElement> | undefined) => {
        const {
            loading = false,
            type = 'button',
            className,
            ...elementProps
        } = props;

        return (
            <button
                // eslint-disable-next-line react/button-has-type
                type={type}
                className={clsx(styles.btn, className && className)}
                disabled={loading || elementProps.disabled}
                ref={ref}
                {...elementProps}
            />
        );
    },
);
export default Button;
