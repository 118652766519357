import React from 'react';
import Input from '../../../Components/Input';
import ActionSelector from '../ActionSelector';
import DimensionSelector from '../DimensionSelector';
import TypographySelector from '../TypographySelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, { VendexTimerMeta } from '../../../Model/VendexElement';
import VendexAction from '../../../Model/VendexAction';
import Select from '../../../Components/Select';

type Props = {
    element: VendexElement;
};

function TimerDetail({ element }: Props) {
    const data = element.metaData as VendexTimerMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string | boolean) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    const handleDurationChange = (value = '') => {
        const parsed = parseInt(value, 10);
        handleChange({
            ...element,
            // eslint-disable-next-line no-restricted-globals
            metaData: { ...data, duration: !isNaN(parsed) ? parsed : 1 },
        });
    };

    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-2 items-start">
                <Select
                    value={data.type}
                    onChange={(e) => handleDataChange('type', e.target.value)}
                    label="type"
                >
                    <option value="STRICT">STRICT TIMER</option>
                    <option value="IDLE">IDLE TIMER</option>
                </Select>
                <Input
                    value={data.duration === 0 ? '' : data.duration}
                    type="number"
                    onChange={(e) => handleDurationChange(e.target.value)}
                    label="Duration (seconds)"
                    helperText="duration should to be greater than zero seconds"
                />
                <Select
                    value={data.showCountdown ? 'Yes' : 'No'}
                    onChange={(e) =>
                        handleDataChange(
                            'showCountdown',
                            e.target.value === 'Yes',
                        )
                    }
                    label="Show Countdown"
                >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Select>
            </div>
            {data.showCountdown && (
                <>
                    <TypographySelector selector={`#${element.id}`} />
                    <PositionSelector selector={`#${element.id}`} />
                    <DimensionSelector selector={`#${element.id}`} />
                </>
            )}
            <ActionSelector
                actions={element.actions}
                onChange={handleActionChange}
                isTimer
            />
        </>
    );
}

export default TimerDetail;
