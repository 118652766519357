import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Viewer from '@vendx/engine';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import {
    Download,
    DownloadCloud,
    Edit3,
    Send,
    Settings,
    Tablet,
    Tool,
} from '../../Components/Icon';
import ExperienceDevices from './ExperienceDevices';
import Experience from '../../Model/Experience';
import ExperienceFeatures from './ExperienceFeatures';
import { AnalyticsService, ExperienceService } from '../../api';
import { useNotification } from '../../Components/Notification';
import { formatDate } from '../../utils/formatText';
import ExperienceData from './ExperienceData';
import NotesEditor from '../../Components/NotesEditor';
import VendExp from '../../Model/VendExp';
import DeviceBoundry from '../Builder/Emulator/DeviceBoundry';
import Tabs from '../../Components/Tabs';
import ExperienceModal from './ExperienceModal';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import AdvancedSettings from './AdvancedSettings';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import AssignDevice from './AssignDevice';
import ExperienceAnalytics from './ExperienceAnalytics';

function ExperienceDetail() {
    const { id } = useParams<{ id: string }>();
    const [exp, setExp] = React.useState<Experience>();
    const [vendExp, setVendExp] = React.useState<VendExp>();
    const notification = useNotification();
    const [loading, setLoading] = React.useState(false);
    const [tab, setTab] = React.useState<string>('DETAILS');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [showAdvanced, setShowAdvanced] = React.useState(false);
    const { user } = useAuth<AuthUser>();
    const [assignModal, setAssignModal] = React.useState(false);
    const confirmation = useConfirmation();

    const getData = () => {
        ExperienceService.GetExperience(id as string).then((response) => {
            setExp(response);
        });
    };

    const getVendExpData = () => {
        ExperienceService.GetVendExpById(id as string).then((response) =>
            setVendExp(response),
        );
    };

    React.useEffect(() => {
        getData();
        getVendExpData();
    }, [id]);

    const handlePropagate = () => {
        ExperienceService.PropagateExperienceChanges(id as string)
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'failed to propagat changes',
                    duration: 6000,
                }),
            )
            .then(() =>
                notification({
                    type: 'success',
                    message: 'successfully propagated changes',
                    duration: 6000,
                }),
            );
    };

    const handleDownload = () => {
        setLoading(true);
        ExperienceService.DownloadExperienceForDevice(id as string)
            .finally(() => setLoading(false))
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'error downloading experience',
                    duration: 6000,
                }),
            );
    };

    const handleChange = (value: string) => {
        ExperienceService.UpdatesNotes(id as string, value).catch(() =>
            notification({
                type: 'error',
                message: 'failed to update notes',
                duration: 6000,
            }),
        );
    };

    const handleDataDownload = () => {
        setLoading(true);
        AnalyticsService.DownloadExperienceData(id as string)
            .finally(() => {
                setLoading(false);
            })
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'failed to download data',
                    duration: 6000,
                }),
            );
    };

    const refreshDevice = () => {
        setTab('REFRESH');
        setTimeout(() => setTab('DETAILS'), 1000);
    };

    return (
        <PortalWrapper>
            <div className="bg-white mb-4">
                <Tabs
                    options={[
                        { label: 'Details', value: 'DETAILS' },
                        { label: 'Analytics', value: 'ANALYTICS' },
                        { label: 'Data', value: 'DATA' },
                    ]}
                    selected={tab}
                    onChange={(val) => setTab(val)}
                />
            </div>
            {exp && (
                <div className="bg-white rounded p-4 flex-grow mb-4">
                    <p className="text-gray-400 text-sm font-light">
                        <strong>Date Updated:</strong>
                        {formatDate(exp.dateUpdated)}
                    </p>
                    <p className="text-xs font-bold mt-2">NAME</p>
                    <h1 className="text-xl lg:text-2xl font-bold">
                        {exp.name}
                    </h1>
                    <p className="text-xs font-bold mt-2">DESCRIPTION</p>
                    <p className="font-thin">{exp.description}</p>
                    <ExperienceFeatures exp={exp} />
                </div>
            )}
            {exp && tab === 'DETAILS' && (
                <div className="flex flex-wrap gap-4">
                    <div className=" flex flex-col gap-4 flex-grow justify-between">
                        <div className="flex flex-wrap gap-3 items-start mb-6 xl:max-w-3xl">
                            <button
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                onClick={() =>
                                    confirmation({
                                        title: 'Propagate Changes',
                                        message:
                                            'Are you sure you want to propagate the changes? All the devices associated with this experience that are not locked will receive the changes once they are in idle state.',
                                        onConfirm: handlePropagate,
                                    })
                                }
                                type="button"
                            >
                                <Send className="w-6 h-6" /> PROPAGATE CHANGES
                            </button>
                            <button
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                onClick={handleDownload}
                                disabled={loading}
                                type="button"
                            >
                                <DownloadCloud />
                                DOWNLOAD ASSETS
                            </button>
                            {user.role === UserRole.SUPERADMIN && (
                                <button
                                    className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                    onClick={() => setShowAdvanced(true)}
                                    disabled={loading}
                                    type="button"
                                >
                                    <Settings />
                                    ADVANCED
                                </button>
                            )}
                            <Link
                                to={`/experiences/${id}/builder`}
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                            >
                                <Tool className="w-6 h-6" /> OPEN BUILDER
                            </Link>
                            <button
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                onClick={() => setIsModalOpen(true)}
                                disabled={loading}
                                type="button"
                            >
                                <Edit3 />
                                EDIT DETAILS
                            </button>
                            <button
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                onClick={handleDataDownload}
                                disabled={loading}
                                type="button"
                            >
                                <Download />
                                DOWNLOAD DATA
                            </button>
                            <button
                                onClick={() => setAssignModal(true)}
                                className="flex gap-2 justify-center items-center bg-white p-2 rounded hover:bg-secondary hover:text-white"
                                disabled={loading}
                                type="button"
                            >
                                <Tablet />
                                Add Device
                            </button>
                        </div>
                        <NotesEditor
                            value={exp?.notes}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="bg-white flex-grow rounded p-4 [&>*]:h-full [&>*]:w-full pointer-events-none min-h-[360px] lg:min-h-[480px]">
                        {vendExp && (
                            <DeviceBoundry>
                                <Viewer experience={vendExp} />
                            </DeviceBoundry>
                        )}
                    </div>
                </div>
            )}
            {tab === 'DETAILS' && <ExperienceDevices />}
            {tab === 'DATA' && <ExperienceData />}
            {tab === 'ANALYTICS' && exp && (
                <ExperienceAnalytics experience={exp} />
            )}
            {isModalOpen && exp && (
                <ExperienceModal
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                    exp={exp}
                    refreshData={getData}
                />
            )}
            {showAdvanced && vendExp && (
                <AdvancedSettings
                    onClose={() => setShowAdvanced(false)}
                    builderVersion={vendExp.builderVersion}
                    version={vendExp.version}
                    experienceId={vendExp.id}
                    refreshData={getVendExpData}
                />
            )}
            {assignModal && (
                <AssignDevice
                    onClose={() => {
                        refreshDevice();
                        setAssignModal(false);
                    }}
                    experienceId={id as string}
                />
            )}
        </PortalWrapper>
    );
}

export default ExperienceDetail;
