import Editor from '@monaco-editor/react';
import React from 'react';
import { BuilderActionType, BuilderContext } from './state';
import IconButton from '../../Components/IconButton';
import View from '../../Model/View';
import { X } from '../../Components/Icon';
import { formatCSS } from '../../utils/builderUtils';
import useResizable from '../../utils/useResizable';

function CSSEditor() {
    const { dispatch } = React.useContext(BuilderContext);
    const { styles } = React.useContext(BuilderContext).state;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editorRef = React.useRef<any>(null);
    const expandableDivRef = React.useRef<HTMLDivElement>(null);
    useResizable(expandableDivRef);

    const closeCss = () => {
        dispatch({ type: BuilderActionType.SET_VIEW, payload: View.EDIT });
    };

    React.useEffect(() => {
        setTimeout(() => document.addEventListener('click', closeCss), 1000);
        return () => {
            document.removeEventListener('click', closeCss);
        };
    }, []);

    const initializeEditor = () => {
        if (editorRef.current) {
            const formatted = formatCSS(styles);
            editorRef.current.setValue(formatted);
        }
    };

    React.useEffect(() => {
        setTimeout(() => initializeEditor(), 1000);
    }, []);

    const handleSave = () => {
        if (editorRef.current) {
            const val = editorRef.current.getValue() as string;
            dispatch({
                type: BuilderActionType.SET_CSS,
                payload: val,
            });
        }
    };
    if (!handleSave) return null;

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            role="none"
            className="h-screen absolute top-0 left-0 z-50 pr-1 border-r border-gray-200"
            style={{ width: '448px' }}
            ref={expandableDivRef}
        >
            <IconButton
                onClick={() =>
                    dispatch({
                        type: BuilderActionType.SET_VIEW,
                        payload: View.EDIT,
                    })
                }
                className="absolute top-0 right-4 w-9 h-9 p-2"
            >
                <X />
            </IconButton>
            <Editor
                theme="vs-light"
                width="100%"
                height="100%"
                defaultLanguage="css"
                defaultValue=""
                onChange={handleSave}
                // eslint-disable-next-line no-return-assign
                onMount={(ref) => (editorRef.current = ref)}
            />
        </div>
    );
}

export default CSSEditor;
