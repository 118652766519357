import React from 'react';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import { AuthService } from '../../api';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser } from '../../Model/AuthUser';
import { passwordIsValid } from '../../utils/validations';
import { useNotification } from '../../Components/Notification';
import { Eye, EyeOff } from '../../Components/Icon/index';
import IconButton from '../../Components/IconButton';

function ResetPassword() {
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [reqError, setReqError] = React.useState('');
    const [isTextHidden, setIsTextHidden] = React.useState(true);
    const { user } = useAuth<AuthUser>();
    const notification = useNotification();

    const handleResetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        if (passwordIsValid(newPassword)) {
            setIsLoading(true);
            AuthService.ResetPassword(password, newPassword, user.id)
                .then(() => {
                    notification({
                        type: 'success',
                        duration: 5000,
                        message: 'Password successfully changed!',
                    });
                    setReqError('');
                    setPassword('');
                    setNewPassword('');
                })
                .catch((err) => {
                    const errorMessage = err.message
                        ? err.message
                        : 'There was an error with the server. Please try again later.';
                    setReqError(errorMessage);
                })
                .finally(() => setIsLoading(false));
        } else {
            setReqError(
                'Please make sure that your password is following the guidelines below.',
            );
        }
    };
    const toggleEye = () => {
        setIsTextHidden(!isTextHidden);
    };

    return (
        <div className="bg-white p-6 rounded">
            {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}

            <h1 className="text-xl font-semibold">Reset Password</h1>
            <p className="text-sm text-gray-600 mt-4 w-96">
                Password must be at least 8 characters in length and must
                contain one lowercase, one uppercase letter, one number and one
                special character
            </p>
            <div className="w-full max-w-xl">
                <div className="relative">
                    <Input
                        label="current password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type={isTextHidden ? 'password' : ''}
                        className="pr-12"
                        required
                    />
                    <IconButton
                        onClick={toggleEye}
                        className="absolute top-7 right-2"
                    >
                        {isTextHidden ? <EyeOff /> : <Eye />}
                    </IconButton>
                </div>

                <div className="relative">
                    <Input
                        label="new password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        type={isTextHidden ? 'password' : ''}
                        className="pr-12"
                        required
                    />
                    <IconButton
                        className="absolute top-7 right-2"
                        onClick={toggleEye}
                    >
                        {isTextHidden ? <EyeOff /> : <Eye />}
                    </IconButton>
                </div>
            </div>

            <Button
                className="bg-primary text-white mt-4"
                onClick={handleResetPassword}
                loading={isLoading}
            >
                Reset password
            </Button>
        </div>
    );
}

export default ResetPassword;
