import React from 'react';
import { BuilderActionType, BuilderContext } from './state';
import { getStyleProperties } from '../../utils/builderUtils';
import NumberInput from './NumberInput';

type Props = {
    selector: string;
};

function DimensionSelector({ selector }: Props) {
    const styleObj = getStyleProperties(selector, ['width', 'height']);
    const [dimension, setDimension] = React.useState({ ...styleObj });
    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (name: string, value: string) => {
        setDimension({ ...dimension, [name]: value });
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: {
                selector,
                properties: { ...dimension, [name]: value },
            },
        });
    };

    return (
        <>
            <p className="font-semibold">Dimension</p>
            <div className="grid grid-cols-2 gap-3">
                <div>
                    <p className="text-xs text-gray-500">Width</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={dimension.width}
                        onChange={handleChange}
                        name="width"
                    />
                </div>
                <div>
                    <p className="text-xs text-gray-500">Height</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={dimension.height}
                        onChange={handleChange}
                        name="height"
                    />
                </div>
            </div>
        </>
    );
}

export default DimensionSelector;
