import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import LogsList from './LogsList';
import { LogService } from '../../api';
import useQuery from '../../utils/useQuery';
import { LogsFilter } from '../../Model/Analytics';
import { useNotification } from '../../Components/Notification';
import { formatDateForDateTimeInput } from '../../utils/formatText';
import getDateOneMonthBeforeToday from '../../utils/getOneMonthBeforeToday';
import Filter from './Filter';
import { DeviceLogGrid } from '../../Model/DeviceLog';

function Logs(): JSX.Element {
    const [logsData, setLogsData] = React.useState<DeviceLogGrid[]>([]);
    const query = useQuery();
    const [total, setTotal] = React.useState(0);

    const [filters, setFilters] = React.useState<LogsFilter>({
        from: formatDateForDateTimeInput(getDateOneMonthBeforeToday()),
        page: 1,
        pageSize: 20,
    });

    const [isInit, setIsInit] = React.useState(false);

    const notification = useNotification();

    React.useEffect(() => {
        setTimeout(() => setIsInit(true), 500);
    }, []);

    React.useEffect(() => {
        setFilters({
            ...filters,
            experienceId: query?.experienceId ?? undefined,
            serialNumber: query?.serialNumber ?? undefined,
        });
    }, [query]);

    const getData = () => {
        LogService.getLogs(filters as LogsFilter).then((response) => {
            setLogsData(response.items);
            setTotal(response.total);
        });
    };

    React.useEffect(() => {
        if (isInit) {
            getData();
        }
    }, [isInit, filters]);

    const downloadAsCsv = () => {
        LogService.DownloadAsCsv(filters as LogsFilter).catch(() =>
            notification({
                type: 'error',
                message: 'download failed',
                duration: 6000,
            }),
        );
    };
    return (
        <PortalWrapper>
            <Filter
                refreshData={getData}
                filters={filters}
                onChange={(value: LogsFilter) =>
                    setFilters({ ...value, page: 1 })
                }
                downloadAsCsv={downloadAsCsv}
            />
            <LogsList
                logsData={logsData}
                onPageChange={(value) =>
                    setFilters({
                        ...filters,
                        ...value,
                    })
                }
                tableState={{
                    page: filters.page as number,
                    pageSize: filters.pageSize as number,
                }}
                total={total}
            />
        </PortalWrapper>
    );
}

export default Logs;
