import React from 'react';
import { useParams } from 'react-router-dom';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import Device, { DeviceDetailResp } from '../../Model/Device';
import { DeviceService } from '../../api';
import Button from '../../Components/Button';
import DeviceModal from './DeviceModal';
import DeviceExperience from './DeviceExperience';
import { Edit3 } from '../../Components/Icon';
import DeviceFeatures from './DeviceFeatures';
import DeviceTimelineHistory from './DeviceTimelineHistory';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import DeviceInventory from './DeviceInventory';
import DeviceCompany from './DeviceCompany';
import { DeviceType } from '../../Model/VendexDevice';
import { ReactComponent as QrIcon } from '../Builder/BuilderToolIcons/qrcode-light.svg';
import DeviceQr from './DeviceQr';
import NotesEditor from '../../Components/NotesEditor';
import { useNotification } from '../../Components/Notification';
import DeviceHealth from './DeviceHealth';
import DeviceInventoryHealth from './DeviceInventoryHealth';
import Tabs from '../../Components/Tabs';
import Checkbox from '../../Components/Checkbox';

function DeviceDetail() {
    const [device, setDevice] = React.useState<DeviceDetailResp>();
    const [isModal, setModal] = React.useState(false);
    const [qrModal, setQrModal] = React.useState(false);
    const { user } = useAuth<AuthUser>();
    const [tab, setTab] = React.useState<string>('DETAILS');
    const notification = useNotification();

    const { id } = useParams<{ id: string }>();
    const getData = () => {
        DeviceService.GetDeviceById(id as string).then((response) => {
            setDevice(response);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleChange = (value: string) => {
        DeviceService.UpdatesNotes(id as string, value).catch(() =>
            notification({
                type: 'error',
                message: 'failed to update notes',
                duration: 6000,
            }),
        );
    };

    const toggleTestMode = () => {
        if (device) {
            DeviceService.UpdateDevice({
                ...device,
                isInTestMode: !device?.isInTestMode,
            }).then(() => {
                setDevice({ ...device, isInTestMode: !device?.isInTestMode });
            });
        }
    };

    const tabOptions = [
        { label: 'Details', value: 'DETAILS' },
        { label: 'Inventory', value: 'INVENTORY' },
    ];

    if (user.role === UserRole.SUPERADMIN) {
        tabOptions.push({
            label: 'Timeline',
            value: 'TIMELINE',
        });
    }
    if (!device) return null;

    return (
        <PortalWrapper>
            <div className="bg-white mb-4">
                <Tabs
                    options={tabOptions}
                    selected={tab}
                    onChange={(val) => setTab(val)}
                />
            </div>
            {tab === 'DETAILS' && (
                <div className="flex gap-4 flex-col justify-between xl:flex-row">
                    <div className="flex flex-wrap gap-4 items-start">
                        <div className="bg-white rounded-md p-4 flex-grow">
                            <p className="text-xs font-bold mt-2">
                                SERIAL NUMBER
                            </p>
                            <h1 className="text-xl lg:text-2xl font-thin">
                                {device?.serialNumber}
                            </h1>
                            <p className="text-xs font-bold mt-2">NICKNAME</p>
                            <p className="text-xl lg:text-2xl font-thin">
                                {device.nickName ?? 'UNTITLED'}
                            </p>
                            <DeviceFeatures device={device} />
                            <p className="text-xs font-bold my-2">
                                TYPE ({DeviceType[device.type]})
                            </p>
                            <div className="flex gap-2">
                                <img
                                    src={
                                        device.type === DeviceType.BIG_VEND
                                            ? '/large.jpg'
                                            : '/mini.jpg'
                                    }
                                    alt="machine type"
                                    className="w-16 h-16"
                                />
                            </div>
                            <div className="flex gap-3 mt-5">
                                <Button
                                    onClick={() => setModal(true)}
                                    className="bg-secondary flex gap-2 text-white justify-center items-center"
                                >
                                    <Edit3 className="w-6 h-6" /> EDIT DEVICE
                                </Button>
                                <Button
                                    onClick={() => setQrModal(true)}
                                    className="bg-secondary flex gap-2 text-white justify-center items-center"
                                >
                                    <QrIcon className="w-6 h-6 text-white fill-white" />{' '}
                                    ADMIN QR
                                </Button>
                            </div>
                        </div>
                        <NotesEditor
                            value={device.notes}
                            onChange={handleChange}
                        />
                        <div className="flex flex-col gap-4 flex-grow">
                            <div className="bg-white rounded-md p-4 flex-grow">
                                <p className="text-lg lg:text-xl font-medium mb-2">
                                    TEST MODE
                                </p>
                                <Checkbox
                                    toggle
                                    checked={device.isInTestMode}
                                    onChange={toggleTestMode}
                                />
                            </div>
                            <div className="bg-white rounded-md p-4 flex-grow">
                                <p className="text-lg lg:text-xl font-medium mb-2">
                                    HEALTH
                                </p>
                                <DeviceHealth health={device.health} />
                            </div>
                            <div className="bg-white rounded-md p-4 flex-grow">
                                <p className="text-lg lg:text-xl font-medium mb-2">
                                    INVENTORY
                                </p>
                                <DeviceInventoryHealth
                                    health={device.inventoryStatus}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4">
                        <DeviceCompany device={device} refreshData={getData} />
                        <DeviceExperience
                            device={device}
                            refreshData={getData}
                        />
                    </div>
                </div>
            )}
            {tab === 'INVENTORY' && (
                <DeviceInventory serialNumber={device.serialNumber} />
            )}
            {tab === 'TIMELINE' && (
                <DeviceTimelineHistory
                    device={device as Device}
                    refreshData={getData}
                />
            )}
            {isModal && (
                <DeviceModal
                    device={device as Device}
                    onClose={() => setModal(false)}
                    refreshData={getData}
                />
            )}
            {qrModal && (
                <DeviceQr
                    serialNumber={device?.serialNumber as string}
                    onClose={() => setQrModal(false)}
                />
            )}
        </PortalWrapper>
    );
}

export default DeviceDetail;
