import React from 'react';
import { BuilderActionType, BuilderContext } from './state';
import { PageType } from '../../Model/PageData';
import Modal from '../../Components/Modal';
import Button from '../../Components/Button';
import { PlusCircle } from '../../Components/Icon';

const types = Object.values(PageType);

function AddPage() {
    const { addPage, pages } = React.useContext(BuilderContext).state;

    const { dispatch } = React.useContext(BuilderContext);
    const handleClose = () => {
        dispatch({
            type: BuilderActionType.TOOGLE_ADD_PAGE,
        });
    };

    const handleAdd = (selected?: PageType) => {
        dispatch({
            type: BuilderActionType.ADD_PAGE,
            payload: selected,
        });
        handleClose();
    };
    return (
        <Modal
            open={addPage}
            close={handleClose}
            className="max-w-xl z-50 overflow-y-auto min-h-0"
            style={{ maxHeight: '80vh' }}
        >
            <div className="p-4">
                <h4 className="text-xl font-semibold">ADD PAGE</h4>
                <br />
            </div>
            <div className="flex flex-wrap gap-2 justify-center">
                <Button
                    onClick={() => handleAdd()}
                    className=" flex flex-col justify-center items-center gap-2 bg-secondary text-white"
                >
                    <PlusCircle />
                    NEW SCREEN
                </Button>
                {types
                    .filter(
                        (type) =>
                            pages.findIndex((page) => page.id === type) === -1,
                    )
                    .map((el) => (
                        <Button
                            key={el}
                            onClick={() => handleAdd(el as PageType)}
                            className=" bg-primary-light flex flex-col justify-center items-center gap-2 text-white"
                        >
                            <PlusCircle />
                            {el}
                        </Button>
                    ))}
            </div>
        </Modal>
    );
}

export default AddPage;
