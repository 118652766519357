import React from 'react';
import { BuilderActionType, BuilderContext } from './state';

type Props = {
    url?: string;
    type?: 'IMAGE' | 'VIDEO' | 'FONT';
    onChange: (url: string) => void;
};

function MediaSelector({ url, onChange, type }: Props) {
    const { dispatch } = React.useContext(BuilderContext);

    const handleClick = () => {
        dispatch({
            type: BuilderActionType.SET_MEDIA_LIBRARY,
            payload: {
                open: 'MEDIA',
                onChange,
                selected: url,
                type,
            },
        });
    };
    if (url && url.length > 0) {
        return (
            <object
                onClick={handleClick}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => e.key === 'Enter' && handleClick()}
                data={url}
                className="w-16 border border-primary rounded-sm cursor-pointer"
                style={{ minHeight: '64px' }}
            >
                thumbnail
            </object>
        );
    }
    return (
        <div
            onClick={handleClick}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.key === 'Enter' && handleClick()}
            className="w-16 border border-primary rounded-sm cursor-pointer bg-gray-200 flex items-center justify-center"
            style={{ minHeight: '64px' }}
        >
            <p>image</p>
        </div>
    );
}

export default MediaSelector;
