import React from 'react';
import IconButton from '../../Components/IconButton';
import { Trash2 } from '../../Components/Icon';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import { useNotification } from '../../Components/Notification';
import { ProductService } from '../../api';
import Product from '../../Model/Product';
import Tooltip from '../../Components/Tooltip';

type Props = {
    product: Product;
    refreshData: () => void;
};

function ProductQuickActions({ product, refreshData }: Props): JSX.Element {
    const confirmationDialog = useConfirmation();
    const notification = useNotification();

    const handleDelete = () => {
        confirmationDialog({
            title: 'Delete Product',
            message: `Are you sure you want to delete this ${product.name}?`,
            onConfirm: () => {
                ProductService.DeleteProduct(product.id)
                    .catch(() => {
                        notification({
                            type: 'error',
                            message: 'Deletion failed',
                            duration: 6000,
                        });
                    })
                    .then(() => refreshData());
            },
        });
    };
    return (
        <div className="w-fit">
            <div className="relative flex p-0">
                <Tooltip text='Delete Product'>
                    <IconButton
                        onClick={handleDelete}
                        btnSize="small"
                        className="text-red-600"
                    >
                        <Trash2 />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

export default ProductQuickActions;
