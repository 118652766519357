/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ColorSelector from './ColorSelector';
import MediaSelector from './MediaSelector';
import { BuilderActionType, BuilderContext } from './state';
import { getStyleProperties } from '../../utils/builderUtils';

type Props = {
    selector: string;
    hasImageSelection?: boolean;
};

function BackgroundSelector({ selector, hasImageSelection = true }: Props) {
    const styleObj = getStyleProperties(selector, [
        'background-image',
        'background-color',
    ]);
    const [BGcolor, setBGColor] = React.useState<string>();
    const [BGimageUrl, setBGUrl] = React.useState<string>();
    const { dispatch } = React.useContext(BuilderContext);

    React.useEffect(() => {
        let bgImg = styleObj['background-image'] ?? '';

        if (bgImg && bgImg.includes('url(')) {
            // trimming the url() part
            bgImg = bgImg.substring(5, bgImg.length - 2);
        } else {
            bgImg = '';
        }
        const bgColor = styleObj['background-color'] ?? '';
        setBGUrl(bgImg);
        setBGColor(bgColor);
    }, [selector]);

    const handleBGColorChange = (value: string) => {
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: {
                selector,
                properties: { 'background-color': value },
            },
        });
        setBGColor(value);
    };

    const handleBGImageChange = (value: string) => {
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: {
                selector,
                properties: {
                    'background-image': `url("${value}")`,
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                },
            },
        });
        setBGUrl(value);
    };

    return (
        <>
            <div className="flex gap-2">
                <p className="w-40 font-semibold">Background Color</p>
                <ColorSelector color={BGcolor} onChange={handleBGColorChange} />
            </div>
            {hasImageSelection && (
                <div className="flex gap-2 mt-4">
                    <p className="w-40 font-semibold">Background Image</p>
                    <MediaSelector
                        url={BGimageUrl}
                        onChange={handleBGImageChange}
                        type="IMAGE"
                    />
                </div>
            )}
        </>
    );
}

export default BackgroundSelector;
