const HOURS: { [key: string]: string } = {
    '0': '12 AM',
    '1': '1 AM',
    '2': '2 AM',
    '3': '3 AM',
    '4': '4 AM',
    '5': '5 AM',
    '6': '6 AM',
    '7': '7 AM',
    '8': '8 AM',
    '9': '9 AM',
    '10': '10 AM',
    '11': '11 AM',
    '12': '12 PM',
    '13': '1 PM',
    '14': '2 PM',
    '15': '3 PM',
    '16': '4 PM',
    '17': '5 PM',
    '18': '6 PM',
    '19': '7 PM',
    '20': '8 PM',
    '21': '9 PM',
    '22': '10 PM',
    '23': '11 PM',
};
const MONTHS: { [key: string]: string } = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
};
const WEEK: { [key: string]: string } = {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
};
const getLabelValue = (timeFrame: string, value: string): string => {
    switch (timeFrame) {
        case 'HOURS':
            return HOURS[value];
        case 'MONTHS':
            return MONTHS[value];
        case 'WEEK':
            return WEEK[value];
        default:
            return value;
    }
};

export default getLabelValue;
