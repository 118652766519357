const emailIsValid = (email: unknown): boolean => {
    if (typeof email !== 'string') {
        return false;
    }
    const exp =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return exp.test(email);
};

const passwordIsValid = (password: unknown): boolean => {
    if (typeof password !== 'string') {
        return false;
    }
    const exp =
        /(?=(.*[0-9]))(?=.*[\\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;

    return exp.test(password);
};

const phoneNumberIsValid = (value: unknown): boolean => {
    if (typeof value !== 'string') {
        return false;
    }
    const regex =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    if (regex.test(value)) {
        return true;
    }
    return false;
};

const passwordAreEqual = (
    password: unknown,
    confirmPassword: unknown,
): boolean => {
    if (typeof password !== 'string' || typeof confirmPassword !== 'string') {
        return false;
    }
    return confirmPassword === password;
};

const zipCodeIsValid = (zip: unknown): boolean => {
    if (typeof zip !== 'string') {
        return false;
    }
    return zip.length === 5;
};

const dateIsValid = (dob: unknown): boolean => {
    if (typeof dob !== 'string') {
        return false;
    }
    const date = new Date(dob);
    return !Number.isNaN(date.getTime());
};

const urlIsValid = (url: unknown): boolean => {
    if (typeof url !== 'string') {
        return false;
    }
    const regex =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    if (regex.test(url)) {
        return true;
    }
    return false;
};

const isValidPriceAmount = (price: any): boolean => {
    // First, check if the input is a number or a string
    if (typeof price === 'number' || typeof price === 'string') {
        // Convert the input to a number
        const priceNum = Number(price);

        // Check if the number is a positive finite number
        if (priceNum >= 0 && Number.isFinite(priceNum)) {
            return true;
        }
    }

    // If the input is not a valid price amount, return false
    return false;
};
function isEndDateGreaterThanStartDate(
    startDateString: string,
    endDateString: string,
) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    if (Number.isNaN(startDate) || Number.isNaN(endDate)) {
        // Invalid date strings
        return false;
    }

    return endDate > startDate;
}

function isSerialNumberValid(serialNumber: string) {
    const regex = /^[0-9A-Fa-f]{10}$/;
    return regex.test(serialNumber);
}

function isClosingTimeAfterOpeningTime(
    openingTime: string,
    closingTime: string,
): boolean {
    const openingTimeParts = openingTime.split(':');
    const closingTimeParts = closingTime.split(':');

    if (openingTimeParts.length !== 2 || closingTimeParts.length !== 2) {
        return false; // Return false if the format is invalid
    }

    const [openingHour, openingMinute] = openingTimeParts;
    const [closingHour, closingMinute] = closingTimeParts;

    const opening = new Date();
    opening.setHours(parseInt(openingHour, 10));
    opening.setMinutes(parseInt(openingMinute, 10));

    const closing = new Date();
    closing.setHours(parseInt(closingHour, 10));
    closing.setMinutes(parseInt(closingMinute, 10));

    return closing > opening;
}

export {
    emailIsValid,
    passwordAreEqual,
    passwordIsValid,
    zipCodeIsValid,
    dateIsValid,
    urlIsValid,
    phoneNumberIsValid,
    isValidPriceAmount,
    isEndDateGreaterThanStartDate,
    isSerialNumberValid,
    isClosingTimeAfterOpeningTime,
};
