import React from 'react';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import DimensionSelector from '../DimensionSelector';
import MediaSelector from '../MediaSelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, {
    PopItem,
    VendexPopItMeta,
} from '../../../Model/VendexElement';

type Props = {
    element: VendexElement;
};

function PopItemDetail({
    pop,
    handleChange,
    handleRemove,
}: {
    pop: PopItem;
    handleChange: (pop: PopItem) => void;
    handleRemove: () => void;
}) {
    const onChange = (name: string, value: string | number) => {
        handleChange({ ...pop, [name]: value });
    };

    return (
        <div className="relative pb-9">
            <hr className="my-4" />
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Width</p>
                <Input
                    onChange={(e) =>
                        onChange('width', parseInt(e.target.value, 10))
                    }
                    type="number"
                    step={1}
                    value={!Number.isNaN(pop.width) ? pop.width : ''}
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Height</p>
                <Input
                    onChange={(e) =>
                        onChange('height', parseInt(e.target.value, 10))
                    }
                    type="number"
                    step={1}
                    value={!Number.isNaN(pop.height) ? pop.height : ''}
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Points</p>
                <Input
                    onChange={(e) =>
                        onChange('point', parseInt(e.target.value, 10))
                    }
                    type="number"
                    step={1}
                    value={!Number.isNaN(pop.point) ? pop.point : ''}
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Source</p>
                <MediaSelector
                    url={pop.source}
                    onChange={(value) => onChange('source', value)}
                    type="IMAGE"
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Poped Source</p>
                <MediaSelector
                    url={pop.popSource}
                    onChange={(value) => onChange('popSource', value)}
                    type="IMAGE"
                />
            </div>
            <div className="flex gap-2 justify-end mt-2">
                <Button
                    className="bg-red-600 text-white text-xs p-2 min-h-0 min-w-0"
                    onClick={handleRemove}
                >
                    Remove
                </Button>
            </div>
        </div>
    );
}

function PopItDetail({ element }: Props) {
    const data = element.metaData as VendexPopItMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (
        name: string,
        value: string | PopItem[] | number,
    ) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    const handleRemove = (index: number) => {
        const temp = JSON.parse(JSON.stringify(data.popItems));
        const resp = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < temp.length; i++) {
            if (i !== index) {
                resp.push(temp[i]);
            }
        }
        handleDataChange('popItems', resp);
    };

    const handleAdd = () => {
        const temp = JSON.parse(JSON.stringify(data.popItems));
        temp.push({
            source: '/vendx-full.png',
            width: 120,
            height: 120,
            point: 1,
        });
        handleDataChange('popItems', temp);
    };

    const handlePopItemChange = (value: PopItem, index: number) => {
        const temp = [...data.popItems];
        temp[index] = value;
        handleDataChange('popItems', temp);
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Name*</p>
                <Input
                    onChange={(e) => {
                        handleDataChange('name', e.target.value);
                    }}
                    value={data.name}
                />
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Items in Screen</p>
                <Input
                    onChange={(e) =>
                        handleDataChange(
                            'itemsInScreen',
                            parseInt(e.target.value, 10),
                        )
                    }
                    type="number"
                    step={1}
                    value={
                        !Number.isNaN(data.itemsInScreen)
                            ? data.itemsInScreen
                            : ''
                    }
                />
            </div>
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <p className="text-gray-600 text-xs">
                To include a running score on the page add a text element with
                Dynamic type and set value to the name of the POP IT GAME
            </p>
            <br />
            <p>
                <strong>Pop Items</strong>
            </p>
            {data.popItems.map((el, index) => (
                <PopItemDetail
                    handleChange={(value) => handlePopItemChange(value, index)}
                    pop={el}
                    handleRemove={() => handleRemove(index)}
                    key={`${index.toString()}${new Date().toLocaleTimeString()}`}
                />
            ))}
            <Button
                onClick={handleAdd}
                className="mt-4 bg-primary-light text-white"
            >
                ADD POP ITEM
            </Button>
        </>
    );
}
export default PopItDetail;
