import React from 'react';
import { CellProps, usePagination, useSortBy, useTable } from 'react-table';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import { DeviceLogGrid, LOGSEVERITY } from '../../Model/DeviceLog';
import Pagination from '../../Components/Pagination';
import LogInfo from './LogInfo';
import { formatDate } from '../../utils/formatText';

type Props = {
    logsData: DeviceLogGrid[];
    onPageChange: (values: { page: number; pageSize: number }) => void;
    tableState: { page: number; pageSize: number };
    total: number;
};

function LogsList({
    logsData,
    onPageChange,
    total,
    tableState,
}: Props): JSX.Element {
    const memoizedData = React.useMemo(() => [...logsData], [logsData]);
    const [selected, setSelected] = React.useState('');

    const getLogSeverity = (value: LOGSEVERITY, testMode = false) => {
        switch (value) {
            case LOGSEVERITY.CRITICAL:
                return (
                    <p className="flex gap-2 items-center">
                        {testMode && (
                            <span
                                className="bg-secondary text-white p-0.5 rounded shadow"
                                style={{ fontSize: '8px' }}
                            >
                                TEST
                            </span>
                        )}
                        <span className="bg-red-600 w-3 h-3 rounded-full" />
                        critical
                    </p>
                );
            case LOGSEVERITY.ERROR:
                return (
                    <p className="flex gap-2 items-center">
                        {testMode && (
                            <span
                                className="bg-secondary text-white p-0.5 rounded shadow"
                                style={{ fontSize: '8px' }}
                            >
                                TEST
                            </span>
                        )}
                        <span className="bg-red-400 w-3 h-3 rounded-full" />
                        error
                    </p>
                );
            case LOGSEVERITY.WARNING:
                return (
                    <p className="flex gap-2 items-center">
                        {testMode && (
                            <span
                                className="bg-secondary text-white p-0.5 rounded shadow"
                                style={{ fontSize: '8px' }}
                            >
                                TEST
                            </span>
                        )}
                        <span className="bg-yellow-600 w-3 h-3 rounded-full" />
                        warning
                    </p>
                );
            default:
                return (
                    <p className="flex gap-2 items-center">
                        {testMode && (
                            <span
                                className="bg-secondary text-white p-0.5 rounded shadow"
                                style={{ fontSize: '8px' }}
                            >
                                TEST
                            </span>
                        )}
                        <span className="bg-blue-600 w-3 h-3 rounded-full" />
                        info
                    </p>
                );
        }
    };

    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Severity',
                accessor: 'severity',
                Cell: ({ row }: CellProps<DeviceLogGrid>) => {
                    return getLogSeverity(
                        row.original.severity,
                        row.original.isTestData,
                    );
                },
            },
            {
                Header: 'Date',
                accessor: 'dateCreated',
                Cell: ({ value }: CellProps<DeviceLogGrid, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: 'Local Date',
                accessor: 'localDateCreated',
                Cell: ({ value }: CellProps<DeviceLogGrid, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: 'SessionId',
                accessor: 'sessionId',
            },
            {
                Header: 'LOG TYPE',
                accessor: 'description',
            },
        ];
        return baseColumns;
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
        useTable(
            {
                columns: memoizedColumns,
                data: memoizedData,
            },
            useSortBy,
            usePagination,
        );

    return (
        <>
            <table
                {...getTableProps()}
                className="w-full mt-6 border border-gray-200"
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}
                                >
                                    {column.render('Header')}
                                    <SortingIcon
                                        isSorted={column.isSorted}
                                        isSortedDesc={column.isSortedDesc}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white border-2px" {...getTableBodyProps()}>
                    {/* @ts-ignore */}
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="hover:bg-gray-100 cursor-pointer border-b border-gray-100"
                                onClick={() => {
                                    setSelected(row.original.id);
                                }}
                            >
                                {/* @ts-ignore */}
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="text-center py-3 border-r border-r-gray-200 px-2"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination
                total={total}
                onChange={onPageChange}
                state={tableState}
            />
            {selected.length > 0 && (
                <LogInfo
                    selected={selected as string}
                    onClose={() => setSelected('')}
                />
            )}
        </>
    );
}

export default LogsList;
