import clsx from 'clsx';
import React from 'react';
import styles from './iconbutton.module.css';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    /** button loading state. The button is disabled during this stage */
    loading?: boolean;
    type?: 'button' | 'reset' | 'submit';
    btnSize?: 'small' | 'medium' | 'large';
    rounded?: boolean;
}

const IconButton = React.forwardRef(
    (props: Props, ref: React.LegacyRef<HTMLButtonElement> | undefined) => {
        const {
            loading = false,
            type = 'button',
            btnSize = 'medium',
            rounded = true,
            className,
            ...elementProps
        } = props;

        return (
            <button
                // eslint-disable-next-line react/button-has-type
                type={type}
                className={clsx(
                    styles.iconbutton,
                    styles[`btn-${btnSize}`],
                    rounded && styles.rounded,
                    className && className,
                )}
                disabled={loading || elementProps.disabled}
                ref={ref}
                {...elementProps}
            />
        );
    },
);

export default IconButton;
